import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import WhatsAppBot from "../Components/WhatsAppBot";
import { getAllBikes } from "../Actions/bikeActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../Components/Meta";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import NotifyMe from "../Components/NotifyMe.js";
import CallBtn from "../Components/CallBtn";

const RangeBikesScreen = () => {
  const { name } = useParams();
  const currencyFormat = (num) => {
    return "₹" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const dispatch = useDispatch();

  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;
  useEffect(() => {
    dispatch(getAllBikes());
  }, [name]);

  const [showModel, setShowModel] = useState(false);

  const [bikeIndex, setBikeIndex] = useState();
  const meta = {
    "x-factor": {
      title: "Best Electric Cycles Under 30000 | Types of X Bicycles",
      description:
        "Discover the best electric cycles under 30000 with our comprehensive guide. Explore types of X bicycles perfect for your budget and lifestyle.",
      canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
    },
    desire: {
      title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
      description:
        "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
      canonicalTag: "https://www.emotorad.com/bikes/range/desire",
    },
  };
  const metaData = {};

  for (const key in meta) {
    if (meta.hasOwnProperty(key) && key == name) {
      metaData["range"] = meta[key];
    }
  }

  return (
    <>
      <Meta
        title={metaData.range.title}
        description={metaData.range.description}
        cannonicalTag={metaData.range.canonicalTag}
      />
      {/* <WhatsAppBot bottom={"5%"} /> */}
      <CallBtn />
      {bikes && <Navbar />}

      <div className="position-relative ">
        {showModel && (
          <NotifyMe
            setShowModel={setShowModel}
            url={
              name === "desire"
                ? "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/product/doodle/lifestyle/LifeStyle_doodle_2.jpg"
                : name === "x-factor"
                ? "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/product/x2/lifestyle/x2-boy-2.jpg"
                : ""
            }
            bike={bikes[bikeIndex].name}
          />
        )}
        <div
          className="d-none d-md-block position-relative range-banner-img"
          style={{
            // https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/range/Range-Page-Banner1.jpg
            backgroundImage: `url('${
              name === "elite"
                ? ""
                : name === "desire"
                ? "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/range/Desire-Range-Banner.jpg"
                : "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/range/X%2Bfactor.jpg"
            }')`,
          }}
        ></div>

        <div
          className="d-md-none d-md-block position-relative banner-img-sm"
          style={{
            backgroundImage: `url('${
              // https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/range/Range-Page-Mobile-Banner.jpg
              name === "elite"
                ? ""
                : name === "desire"
                ? "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/range/Desire-Range-mobile-Banner.jpg"
                : "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/range/X%2Bfactor+2.jpg"
            }')`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        ></div>
      </div>
      <div className="container mt-lg-5 mt-3">
        <h2 className="mt-lg-5 mb-1 fw-bold font-mon mt-4">
          Our <span className="text-capitalize">{name}</span> Ebike Range
        </h2>

        <div className="row mt-4 mb-4">
          {bikes &&
            bikes?.map(
              (product, i) =>
                product.subCategory === name && (
                  <div
                    key={i}
                    className="col-12 mt-5 mt-lg-3 pt-4  pt-lg-0 col-lg-4  "
                  >
                    <div
                      className="  h-100  bike-card  pt-lg-4 pb-lg-4"
                      style={{ height: "100%" }}
                    >
                      <div className="flag"></div>

                      <Link
                        className="text-decoration-none text-dark"
                        to={`/bikes/${product.urlName}`}
                      >
                        <div className="bike-img-bg position-relative">
                          <img
                            src={product.cartImages[0]}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <h5 className="card-bike-name mt-3 mb-4 text-center text-dark">
                          {product.name}
                        </h5>
                        {product.highLights?.map((highlight, i) => (
                          <p
                            key={i}
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              width: "100%",
                              fontSize: "0.8em",
                            }}
                          >
                            <i className="fa-solid fa-circle-stop text-em "></i>{" "}
                            {highlight}
                          </p>
                        ))}
                        <hr />
                        <p className="card-price text-dark d-flex">
                          {currencyFormat(product.price)}
                          {product.slashPrice > 0 && (
                            <span className="">
                              {" "}
                              {currencyFormat(product.slashPrice)}
                            </span>
                          )}
                        </p>{" "}
                      </Link>

                      <Link
                        className="text-decoration-none"
                        to={`/bikes/${product.urlName}`}
                      >
                        {product.inStock > 0 ? (
                          <Link
                            className="text-decoration-none   "
                            to={`/bikes/${product.urlName}`}
                          >
                            <button className="all-bikes-bike-card-btn">
                              Buy Now
                            </button>
                          </Link>
                        ) : (
                          <button className="out-stock-btn">
                            Out of Stock
                          </button>
                        )}
                      </Link>
                    </div>{" "}
                  </div>
                )
            )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RangeBikesScreen;
