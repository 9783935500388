import React, { useState, useEffect } from "react";
import "../CSS/welcomePopup.css"; // For additional styling
import axios from "axios";

const WelcomePopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Check if popup has already been shown in this session or permanently closed
    const hasShownPopup = localStorage.getItem("hasShownPopup");

    if (!hasShownPopup) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 5000);

      return () => clearTimeout(timer);
    }

    // Listen for storage events to close popup in all tabs
    const handleStorageChange = (event) => {
      if (event.key === "popupShown") {
        setShowPopup(false);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const closePopup = () => {
    setShowPopup(false);
    localStorage.setItem("hasShownPopup", "true"); // Ensure it's not shown again
    window.localStorage.setItem("popupShown", Date.now()); // Broadcast to other tabs
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const formResponse = await axios.post(
        "/api/form/welcome-sign-up",
        {
          name,
          email,
        },
        config
      );
      setShowPopup(false);
      localStorage.setItem("hasShownPopup", "true");
      window.localStorage.setItem("popupShown", Date.now());
    } catch (error) {
      setShowPopup(false);
    }
  };

  return (
    <>
      {showPopup && (
        <div
          className="popup-overlay d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="popup-container">
            <div className="popup-content row">
              {/* Left Image Section */}
              <div className="col-md-6 p-0">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/Mobile-newsletter.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/Desktop-newsletter.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/Desktop-newsletter.jpg"
                    alt="Banner1"
                    className="d-block w-100"
                  />
                </picture>
              </div>

              {/* Right Content Section */}
              <div className="col-md-6 p-lg-4 px-4 py-3 d-flex flex-column justify-content-center welcome-popup-right">
                <p className="mb-1 mb-lg-2 text-center heading-welcome-popup metropolis-medium">
                  Mega Republic Day Sale!
                </p>
                <p className="text-muted text-center mb-3 mb-lg-4 text-welcome-popup px-2 px-lg-0">
                  To avail more super offers, fill in your details.
                </p>
                <form
                  className="d-flex justify-content-center px-3 px-lg-4"
                  style={{ flexDirection: "column" }}
                  onSubmit={submitForm}
                >
                  <div className="mb-2 mb-lg-3 text-center">
                    <input
                      type="text"
                      className="form-control mb-lg-4 mb-3 welcome-popup-input-field"
                      placeholder="Enter your name"
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        e.target.setCustomValidity("");
                      }}
                      style={{
                        textTransform: " capitalize",
                      }}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Name should have at least 2 characters and contain only alphabets."
                        )
                      }
                    />
                    <input
                      type="email"
                      className="form-control mb-3 welcome-popup-input-field"
                      placeholder="Enter your email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn mx-auto rounded-5 px-4 py-lg-2 px-lg-5 mb-2 mb-lg-3 button-welcome-popup"
                  >
                    Sign Up
                  </button>
                </form>
                <small
                  className="text-muted text-center px-2 px-lg-0"
                  style={{ fontSize: "0.7em" }}
                >
                  By signing up, you agree to receive email communication from
                  EMotorad according to our{" "}
                  <a href="/privacy-policy">Privacy Policy</a>.
                </small>
              </div>
            </div>
            <button
              className="btn-close popup-close-btn m-lg-2"
              onClick={closePopup}
            ></button>
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomePopup;
