import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Disable browser's built-in scroll restoration
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    // Scroll to the top after the page has loaded completely
    setTimeout(() => {
      window.scrollTo(0, 0); // Scroll to top instantly
    }, 0); // Short delay to ensure the scroll happens after rendering
  }, [pathname]); // This runs every time the route (pathname) changes

  return null;
};

export default ScrollToTop;
