import React, { useState, useEffect } from "react";

import logo from "../images/mainlogo.png";
import logo2 from "../images/logo2.png";
import bluelogo from "../images/EM-LOGO_Blue.png";
import navlogo from "../images/nav.png";
// import cancel from "../images/cancel.png";
import { Link, useLocation } from "react-router-dom";
import { redirectDomain } from "../Actions/redirectAction";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import "../CSS/breadcrumb.css";
import formulafunlogo from "../images/formulafunlogo.png";
import formulafunwhiteLogo from "../images/FormulaFunWhiteLogo.png";
import ParticlesContainer from "./ParticlesContainer";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Snowfall from "react-snowfall";

// import { getloginUserInfo } from "../Actions/userAction";

const Navbar = ({ showtestride, tRexNavbar, name, buylink, breadcrumb }) => {
  const cart = useSelector((state) => state.cart);
  const cartItemsFromStorage = localStorage.getItem("cartItems");
  let cartItems;

  if (cartItemsFromStorage) {
    if (JSON.parse(cartItemsFromStorage).length > 0)
      cartItems = JSON.parse(cartItemsFromStorage);
  } else {
    cartItems = cart.cartItems;
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);
  useEffect(() => {
    redirectDomain();
  }, []);
  const [open, openNav] = useState(false);

  const closeNavforDeadLink = () => {
    openNav(false);
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const options = {
    autoPlay: true,
    background: {
      color: {
        value: "#ededed", // Optional, use transparent if the image should show fully
      },
    },
    particles: {
      color: {
        value: "black",
      },
      move: {
        angle: {
          offset: 0,
          value: 90,
        },
        decay: 0,
        direction: "bottom",
        drift: 0,
        enable: true,
        speed: 2,
      },
      number: {
        density: {
          enable: true,
          area: 1000,
          factor: 1500,
        },
        limit: 0,
        value: 400,
      },
      size: {
        value: 10,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false,
        },
      },
    },
  };
  return (
    <>
      {/* desktop navbar */}
      <div
        className="position-sticky navigation w-100"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className=" desktop-nav m-0 p-0 d-none d-lg-block p-3 ps-lg-5 pe-lg-5">
          <div className="row">
            <div className="col-2 p-0">
              {/* <ParticlesContainer /> */}
              <div className="">
                <Link to="/" className="text-decoration-none">
                  <img
                    src={bluelogo}
                    className="img-fluid"
                    style={{ width: "150px" }}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-8 p-0 d-flex nav-items  align-items-center justify-content-center">
              {" "}
              <div className="dropdown">
                <Link to={"/bikes"} className="text-decoration-none">
                  <p
                    className="m-0 dropdown-heading"
                    style={{ cursor: "pointer" }}
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                  >
                    ALL BIKES
                    <i className="fa-solid ms-2 fa-angle-down"></i>
                  </p>
                </Link>

                <div
                  className="dropdown-content dropdown-content-4"
                  style={{ minWidth: "180px" }}
                >
                  <div className="mt-3 dropdown-content-list">
                    <div className="x-factor-category">
                      <Link className="text-decoration-none">
                        <div className="pt-4 p-3 nav-border-bottom nav-link">
                          <p className="m-0">X-Factor</p>
                          <small>Starting at ₹24,999</small>
                        </div>
                      </Link>
                      <div
                        className="dropdown-content-x-factor dropdown-list-x-factor text-center p-2"
                        style={{
                          minWidth: "150px",
                          border: "1px solid #004dac",
                        }}
                      >
                        <Link to={"/bikes/x1"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">X1</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/x2"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">X2</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/stx"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">ST-X</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/x1-pro-legend-07"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">Legend 07</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/x3"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">X3</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/x1-limited-edition"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">X1 Ltd Edition</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/neo"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">Neo</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="desire-category">
                      <Link className="text-decoration-none">
                        <div className="pt-4 p-3 nav-border-bottom nav-link">
                          <p className="m-0">Desire</p>
                          <small>Starting at ₹34,999</small>
                        </div>
                      </Link>
                      <div
                        className="dropdown-content-desire dropdown-list-desire text-center p-2"
                        style={{
                          minWidth: "150px",
                          border: "1px solid #004dac",
                        }}
                      >
                        <Link to={"/bikes/t-rex-air"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">T-Rex Air</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/t-rex+"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">T-Rex+</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/t-rex-pro"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">T-Rex Pro</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/doodle-pro"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">Doodle Pro</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/doodle-v3"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">Doodle V3</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/doodle-v2"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">Doodle V2</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/emx"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">EMX</p>
                          </div>
                        </Link>
                        <hr style={{ borderColor: "black" }} />
                        <Link to={"/bikes/emx+"}>
                          <div className="nav-border-bottom nav-link">
                            <p className="m-0">EMX+</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <Link className="text-decoration-none" to={"/bikes/lile"}>
                        <div className="pt-4 p-3 nav-border-bottom nav-link">
                          <p className="m-0">Lil E</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dropdown ">
                <p
                  className="m-0 dropdown-heading"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                >
                  ACCESSORIES
                  <i className="fa-solid ms-2 fa-angle-down"></i>
                </p>
                <div
                  className="dropdown-content dropdown-content-4"
                  style={{ minWidth: "180px" }}
                >
                  <div className=" mt-3  dropdown-content-list">
                    <Link to="/accessories" className="text-decoration-none ">
                      <p className="m-0   pt-4 p-3 nav-border-bottom nav-link">
                        EMotorad
                      </p>
                    </Link>
                    <Link
                      to="/accessories/xcape"
                      className="text-decoration-none "
                    >
                      <p className="m-0   pt-4 p-3 nav-border-bottom nav-link">
                        Xcape
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="dropdown ">
                <p
                  className="m-0 dropdown-heading"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                >
                  STORY
                  <i className="fa-solid ms-2 fa-angle-down"></i>
                </p>
                <div
                  className='/* The above code appears to be a comment section in JavaScript. It includes a
								comment block enclosed within /* and */, indicating that the content within
								is not executed by the JavaScript interpreter. Additionally, there is a
								class attribute "dropdown-content dropdown-content-3" which suggests that it
								may be related to styling or functionality for a dropdown menu in a web
								application. */
								dropdown-content dropdown-content-3'
                >
                  <div className=" mt-3  dropdown-content-list">
                    <Link to="/ourstory" className="text-decoration-none ">
                      <p className="m-0  pt-4 p-3 nav-border-bottom nav-link">
                        About
                      </p>
                    </Link>

                    <Link to="/blogs" className="text-decoration-none">
                      <p className="m-0  pt-4 p-3 nav-border-bottom nav-link">
                        Blogs
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="dropdown ">
                <p
                  className="m-0 dropdown-heading"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                >
                  ASSURANCE
                  <i className="fa-solid ms-2 fa-angle-down"></i>
                </p>
                <div
                  className="dropdown-content dropdown-content-4"
                  style={{ minWidth: "180px" }}
                >
                  <div className=" mt-3  dropdown-content-list">
                    <Link to="/warranty" className="text-decoration-none ">
                      <p className="m-0   pt-4 p-3 nav-border-bottom nav-link">
                        Warranty
                      </p>
                    </Link>
                    <Link
                      to="/e-bicycle-insurance"
                      className="text-decoration-none "
                    >
                      <p className="m-0   pt-4 p-3 nav-border-bottom nav-link">
                        Insurance
                      </p>
                    </Link>
                    <Link to="/emi" className="text-decoration-none ">
                      <p className="m-0   pt-4 p-3 nav-border-bottom nav-link">
                        EMI
                      </p>
                    </Link>
                    <Link
                      to="/know-your-ecycle"
                      className="text-decoration-none "
                    >
                      <p className="m-0 pt-4 p-3 nav-border-bottom nav-link">
                        Know Your E-Cycle
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="">
                <Link
                  className="text-decoration-none text-dark "
                  to={"/contact-us"}
                >
                  <p
                    className="m-0 dropdown-heading-1"
                    style={{ cursor: "pointer" }}
                  >
                    CONTACT US
                  </p>
                </Link>
              </div>
              <div className=" ">
                <Link
                  className="text-decoration-none text-light "
                  to={"/find-e-bicycle-store"}
                >
                  <p
                    className="m-0 dropdown-heading-1"
                    style={{ cursor: "pointer" }}
                  >
                    FIND STORE
                  </p>
                </Link>
              </div>
              {/* <div>
                <Link to="/formula-fun" className="text-decoration-none">
                  <img
                    src={formulafunlogo}
                    className="img-fluid"
                    style={{ width: "100px" }}
                    alt=""
                  />
                </Link>
              </div> */}
            </div>
            <div
              className="col-2 p-0 d-flex justify-content-end align-items-center"
              style={{ gap: "2em" }}
            >
              <Link
                to={"/testride"}
                className="book-btn btn border-0 text-light text-decoration-none"
              >
                BOOK A TEST RIDE
              </Link>
              <div className="pt-2">
                <Link to="/cart" className="text-decoration-none">
                  <Icon
                    icon="mdi:cart"
                    width="1.5em"
                    height="1.5em"
                    style={{ color: "#004dac" }}
                  />
                  <sup className="text-decoration-none text-light  font-bold">
                    {cartItems &&
                      cartItems.length > 0 &&
                      cartItems.reduce((sum, e) => sum + e.qty, 0)}
                  </sup>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {tRexNavbar && (
          <div
            className="d-none d-lg-block"
            style={{
              backgroundColor: "#D9D9D9",
              borderBottom: "2px solid rgb(197, 197, 197)",
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center container p-3"
              style={{ gap: "1em" }}
            >
              <Link
                to={"/t-rex-family"}
                style={{ color: "inherit" }}
                className="text-decoration-none p-0 mb-1 me-4"
              >
                <Icon
                  icon="ic:round-home"
                  width="1.5em"
                  height="1.5em"
                  style={{ color: "black" }}
                />
              </Link>
              <h1
                style={{
                  fontSize: "1.2em",
                  fontFamily: "Metropolis-Extra-Bold",
                }}
                className="mb-0 d-lg-block d-none"
              >
                {name}
              </h1>
              <h1
                style={{ fontSize: "1em", fontFamily: "Metropolis-Extra-Bold" }}
                className="mb-0 d-lg-none"
              >
                {name}
              </h1>
              <div
                style={{
                  width: "40%",
                  background:
                    "linear-gradient(to right, #000000 0%, #d9d9d9 100%)",
                  height: "1px",
                }}
              ></div>
              <div className="d-lg-block d-none">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "2em" }}
                >
                  {/* <Link
                    style={{ color: "inherit" }}
                    className="text-decoration-none"
                    to={overview}
                  >
                    Overview
                  </Link>
                  <Link
                    style={{ color: "inherit" }}
                    className="text-decoration-none"
                    to={tech}
                  >
                    Tech Specs
                  </Link> */}
                  <div className="btn-buy-now text-uppercase rounded-5">
                    <Link
                      to={buylink}
                      style={{ color: "inherit" }}
                      className="my-2 text-decoration-none"
                    >
                      Buy Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {breadcrumb && (
          <div className="breadcrumb-container d-lg-block d-none px-3 px-lg-5 py-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item m-0">
                  <Link to="/">Home</Link>
                </li>
                {pathnames.map((value, index) => {
                  const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                  const isLast = index === pathnames.length - 1;
                  const displayValue =
                    value.length > 12 ? value.slice(0, 12) + "..." : value;

                  return isLast ? (
                    <li
                      key={index}
                      className="breadcrumb-item active m-0"
                      aria-current="page"
                    >
                      {displayValue.charAt(0).toUpperCase() +
                        displayValue.slice(1)}
                    </li>
                  ) : (
                    <li key={index} className="breadcrumb-item m-0">
                      <Link to={routeTo}>
                        {displayValue.charAt(0).toUpperCase() +
                          displayValue.slice(1)}
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </nav>
          </div>
        )}
      </div>
      {/* Mobile Navbar */}
      <div className="col-12 mobile-nav p-0 pe-0 d-lg-none ">
        <div className=" w-100 d-flex pe-2 ps-2 justify-content-between border-bottom align-items-center p-1 pt-2 ps-3 pe-3 pb-2">
          <Link to="/">
            <img
              src={bluelogo}
              className=""
              style={{ width: "130px" }}
              alt="em-logo"
            />
          </Link>
          <div className="d-flex align-items-center" style={{ gap: "1.5em" }}>
            {" "}
            <Link to="/cart" className="text-decoration-none">
              <span
                className="iconify"
                data-icon="clarity:shopping-cart-line"
                style={{ color: "#004dac" }}
                data-width="25"
              ></span>
              <sup className="text-decoration-none text-light  font-bold">
                {cartItems &&
                  cartItems.length > 0 &&
                  cartItems.reduce((sum, e) => sum + e.qty, 0)}
              </sup>
            </Link>
            <p
              className="m-0"
              style={{ background: "#fff" }}
              onClick={() => openNav(true)}
            >
              {" "}
              <span
                className="iconify"
                data-icon="ic:round-menu"
                style={{ color: "#004dac" }}
                data-width="35"
              ></span>
            </p>
          </div>
        </div>
        {tRexNavbar && (
          <div
            className="d-lg-none"
            style={{
              backgroundColor: "#D9D9D9",
              borderBottom: "2px solid rgb(197, 197, 197)",
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center p-3"
              style={{ gap: "1em" }}
            >
              <Link
                to={"/t-rex-family"}
                style={{ color: "inherit" }}
                className="text-decoration-none p-0 mb-1"
              >
                <Icon
                  icon="ic:round-home"
                  width="1.3em"
                  height="1.3em"
                  style={{ color: "black" }}
                />
              </Link>
              <h1
                style={{
                  fontSize: "0.8em",
                  fontFamily: "Metropolis-Extra-Bold",
                }}
                className="mb-0"
              >
                {name}
              </h1>
              <div
                className=""
                style={{
                  width: "25%",
                  background:
                    "linear-gradient(to right, #000000 0%, #d9d9d9 100%)",
                  height: "1px",
                }}
              ></div>
              <div className="">
                <div className="background-em text-uppercase rounded-5 text-center text-light px-4">
                  <Link
                    to={buylink}
                    style={{ color: "inherit", fontSize: "0.7em" }}
                    className="my-2 text-decoration-none"
                  >
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: "35px" }}
                    >
                      Buy Now
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {breadcrumb && (
          <div className="breadcrumb-container d-lg-none px-3 px-lg-5 py-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item m-0">
                  <Link to="/">Home</Link>
                </li>
                {pathnames.map((value, index) => {
                  const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                  const isLast = index === pathnames.length - 1;
                  const displayValue =
                    value.length > 12 ? value.slice(0, 12) + "..." : value;

                  return isLast ? (
                    <li
                      key={index}
                      className="breadcrumb-item active m-0"
                      aria-current="page"
                    >
                      {displayValue.charAt(0).toUpperCase() +
                        displayValue.slice(1)}
                    </li>
                  ) : (
                    <li key={index} className="breadcrumb-item m-0">
                      <Link to={routeTo}>
                        {displayValue.charAt(0).toUpperCase() +
                          displayValue.slice(1)}
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </nav>
          </div>
        )}
        {showtestride && (
          <div className="background-em position-sticky d-flex d-md-none align-items-center justify-content-between p-3">
            <div>
              <div className="d-flex text-light align-content-center">
                <Icon icon="material-symbols:star" width={20} />
                <Icon icon="material-symbols:star" width={20} />
                <Icon icon="material-symbols:star" width={20} />
                <Icon icon="material-symbols:star" width={20} />
                <Icon icon="material-symbols:star" width={20} />
              </div>
              <small
                style={{ fontSize: "0.7em" }}
                className="text-center text-light"
              >
                India’s Top-rated electric cycles
              </small>
            </div>
            <div>
              <Link
                to={"/testride"}
                className="btn btn-light fw-bold text-em"
                style={{ fontSize: "0.68rem" }}
              >
                Book Test Ride
              </Link>
            </div>
          </div>
        )}
      </div>

      <div
        className={`mobile-sidenavbar d-lg-none ${
          open ? "navbar-open" : "navbar-off"
        } `}
      >
        <div className="p-2">
          <div className="d-flex p-1 pe-3 align-items-center justify-content-between">
            <Link to="/" onClick={closeNavforDeadLink}>
              <img
                src={logo}
                className=""
                style={{ width: "130px" }}
                alt="logo"
              />
            </Link>
            <div className="" onClick={() => openNav(false)}>
              <i
                className="fa-solid fa-xmark"
                style={{ fontSize: "1.7em" }}
              ></i>
            </div>
          </div>

          <div className="nav-links text-light  ps-1 ">
            <hr className=" mt-4 " />

            <Link
              to={"/"}
              className="mt-2  text-decoration-none "
              onClick={closeNavforDeadLink}
            >
              <div className="pe-4">
                <p className="mb-1 mt-1 text-light">Home</p>
              </div>
            </Link>

            <hr className="mt-4 " />

            <div className="pe-4">
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <p className="mb-0 mt-1 ">Bikes</p>
                <span
                  className="iconify"
                  data-icon="ep:arrow-down"
                  style={{ color: "white" }}
                  data-width="17"
                  data-height="17"
                ></span>
              </div>

              <div
                className="collapse rounded-3 border-r-5 mt-4 p-3 "
                style={{
                  background: " #276ABB",
                }}
                id="collapseExample"
              >
                <Link
                  to={"/bikes"}
                  className="mt-2 fw-bold text-light   text-decoration-none"
                  onClick={closeNavforDeadLink}
                >
                  All Bikes
                </Link>
                <hr className="bg-light " />

                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsex-factor"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <div>
                    <p className="m-0">X-Factor</p>
                    <small>Starting at ₹24,999</small>
                  </div>
                  <span
                    className="iconify"
                    data-icon="ep:arrow-down"
                    style={{ color: "white" }}
                    data-width="17"
                    data-height="17"
                  ></span>
                </div>
                <div
                  className="collapse rounded-3 border-r-5 mt-4 p-3 "
                  style={{
                    background: "white",
                  }}
                  id="collapsex-factor"
                >
                  <Link
                    className="text-decoration-none text-em"
                    to={"/bikes/x1"}
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">X1</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    className="text-decoration-none text-em"
                    to={"/bikes/x2"}
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">X2</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    className="text-decoration-none text-em"
                    to={"/bikes/stx"}
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">ST-X</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    className="text-decoration-none text-em"
                    to={"/bikes/x1-pro-legend-07"}
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">Legend 07</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    className="text-decoration-none text-em"
                    to={"/bikes/x3"}
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">X3</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    className="text-decoration-none text-em"
                    to={"/bikes/x1-limited-edition"}
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">X1 Ltd Edition</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    className="text-decoration-none text-em"
                    to={"/bikes/neo"}
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">Neo</p>
                    </div>
                  </Link>
                </div>
                <hr className="bg-light " />

                <div
                  className="d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsedesire"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <div>
                    <p className="m-0">Desire</p>
                    <small>Starting at ₹34,999</small>
                  </div>
                  <span
                    className="iconify"
                    data-icon="ep:arrow-down"
                    style={{ color: "white" }}
                    data-width="17"
                    data-height="17"
                  ></span>
                </div>
                <div
                  className="collapse rounded-3 border-r-5 mt-4 p-3 "
                  style={{
                    background: "white",
                  }}
                  id="collapsedesire"
                >
                  <Link
                    to={"/bikes/t-rex-air"}
                    className="text-em text-decoration-none"
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">T-Rex Air</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    to={"/bikes/t-rex+"}
                    className="text-em text-decoration-none"
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">T-Rex+</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    to={"/bikes/t-rex-pro"}
                    className="text-em text-decoration-none"
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">T-Rex Pro</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    to={"/bikes/doodle-pro"}
                    className="text-em text-decoration-none"
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">Doodle Pro</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    to={"/bikes/doodle-v3"}
                    className="text-em text-decoration-none"
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">Doodle V3</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    to={"/bikes/doodle-v2"}
                    className="text-em text-decoration-none"
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">Doodle V2</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    to={"/bikes/emx+"}
                    className="text-em text-decoration-none"
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">EMX+</p>
                    </div>
                  </Link>
                  <hr style={{ borderColor: "black" }} />
                  <Link
                    to={"/bikes/emx"}
                    className="text-em text-decoration-none"
                  >
                    <div className="nav-border-bottom nav-link">
                      <p className="m-0">EMX</p>
                    </div>
                  </Link>
                </div>
                <hr className="bg-light " />
                <Link
                  to={"/bikes/lile"}
                  className="mt-2 fw-bold text-light   text-decoration-none"
                  onClick={closeNavforDeadLink}
                >
                  Lil E
                </Link>
              </div>
            </div>
            <hr className=" mt-4 " />
            <div className="pe-4">
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample0"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <p className="mb-0 mt-1">Accessories</p>
                <span
                  className="iconify"
                  data-icon="ep:arrow-down"
                  style={{ color: "white" }}
                  data-width="17"
                  data-height="17"
                ></span>
              </div>

              <div
                className="collapse rounded-3 border-r-5 mt-4 p-3 "
                style={{
                  background: " #276ABB",
                }}
                id="collapseExample0"
              >
                <Link
                  to={"/accessories"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 fw-bold  text-light  text-decoration-none"
                >
                  EMotorad
                </Link>

                <hr className=" " />
                <Link
                  to={"/accessories/xcape"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 fw-bold  text-light text-decoration-none"
                >
                  Xcape
                </Link>
              </div>
            </div>

            <hr className="mt-4 " />
            <div className="pe-4">
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample1"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <p className="mb-0 mt-1">Story</p>
                <span
                  className="iconify"
                  data-icon="ep:arrow-down"
                  style={{ color: "white" }}
                  data-width="17"
                  data-height="17"
                ></span>
              </div>

              <div
                className="collapse rounded-3 border-r-5 mt-4 p-3 "
                style={{
                  background: " #276ABB",
                }}
                id="collapseExample1"
              >
                <Link
                  to={"/ourstory"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 fw-bold  text-light  text-decoration-none"
                >
                  About Us
                </Link>

                <hr className=" " />
                <Link
                  to={"/blogs"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 fw-bold  text-light text-decoration-none"
                >
                  Blogs
                </Link>
              </div>
            </div>
            <hr className="mt-4 " />
            <div className="pe-4">
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample3"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <p className="mb-0 mt-1">Assurance</p>
                <span
                  className="iconify"
                  data-icon="ep:arrow-down"
                  style={{ color: "white" }}
                  data-width="17"
                  data-height="17"
                ></span>
              </div>

              <div
                className="collapse rounded-3 border-r-5 mt-4 p-3 "
                style={{
                  background: " #276ABB",
                }}
                id="collapseExample3"
              >
                <Link
                  to={"/warranty"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 fw-bold  text-light text-decoration-none"
                >
                  Warranty
                </Link>
                <hr className=" " />
                <Link
                  to={"/insurance"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 fw-bold  text-light text-decoration-none"
                >
                  Insurance
                </Link>
                <hr className=" " />
                <Link
                  to={"/emi"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 fw-bold  text-light text-decoration-none"
                >
                  EMI
                </Link>
                <hr className=" " />
                <Link
                  to={"/know-your-ecycle"}
                  onClick={closeNavforDeadLink}
                  className="mt-2 fw-bold  text-light text-decoration-none"
                >
                  Know Your E-Cycle
                </Link>
              </div>
            </div>

            <hr className=" mt-4 " />

            <div className="pe-4">
              <div className="d-flex justify-content-between align-items-center">
                <Link
                  to={"/contact-us"}
                  className="mt-2  text-light   text-decoration-none"
                  onClick={closeNavforDeadLink}
                >
                  <p className="mb-0 mt-1 ">Contact Us</p>
                </Link>
              </div>
            </div>
            <hr className=" mt-4 " />
            <Link
              to={"/find-e-bicycle-store"}
              onClick={closeNavforDeadLink}
              className="mt-2 text-light  text-decoration-none"
            >
              <div className="pe-4">
                <p className="mb-1 mt-1">Find Store</p>
              </div>
            </Link>
            <hr className="mt-4 " />
            <Link
              to={"/testride"}
              className="  text-light  text-decoration-none"
              onClick={closeNavforDeadLink}
            >
              Book A Test Ride
            </Link>
            <hr className="mt-4 " />
            {/* <div>
              <Link to="/formula-fun" className="text-decoration-none">
                <img
                  src={formulafunwhiteLogo}
                  className="img-fluid"
                  style={{ width: "100px" }}
                  alt=""
                />
              </Link>
            </div>
            <hr className="mt-4 " /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
