import React, { useState, useEffect } from "react";
import "../CSS/landing-page3.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import "@splidejs/splide/css";
import CallBtn from "../Components/CallBtn";
import WhatsAppBot from "../Components/WhatsAppBot";
import Meta from "../Components/Meta";

function LandingTestScreen() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [modeOfPurchase, setModeOfPurchase] = useState("");
  const [useCase, setUseCase] = useState("");
  const [budget, setBudget] = useState("");
  const [language, setLanguage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/form/landingBookNow",
      {
        name,
        email,
        phoneNumber,
        city,
        modeOfPurchase,
        useCase,
        budget,
        language,
      },
      config
    );
    if (data) {
      navigate("/book-submitted");
    }
  };
  const dispatch = useDispatch();

  const currencyFormat = (num) => {
    return "₹" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <>
      <Meta
        title={"Book Your Test Ride Now | EMotorad"}
        description={
          "EMotorad brings a range of the best electric bikes that are affordable &amp; futuristic. Crafted with the state of art components, at the best price. Book your test ride now!"
        }
        cannonicalTag={"https://emotorad.com/book-now"}
      />
      <div className="container-fluid p-0">
        <div className="landingpage-main-div bg-dark position-relative">
          <div
            className="starting-at  position-absolute pt-2 ps-3 pe-5 d-none d-lg-block"
            style={{}}
          >
            <p className="mb-0 fw-bold">Starting from</p>
            <h3 className="fw-bold">₹24,999/-</h3>
          </div>
          {/* <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/black.png"
            className="img-fluid landingpage-main-logo-sm d-block d-lg-none mx-auto ps-2"
            alt=""
          /> */}
          <div className="landingpage-form-div d-none d-lg-block">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/Untitled-1-01.png"
              className="img-fluid landingpage-main-logo d-block mx-auto"
              alt=""
            />

            <form
              onSubmit={submit}
              className="p-lg-4  landingpage-form-div-main mt-lg-4 "
            >
              <h6
                className=""
                style={{
                  fontWeight: "600",
                  fontSize: "1.1em",
                }}
              >
                Get up to ₹17000 off on EMotorad electric cycles*
              </h6>
              <div className="mt-lg-4" id="landingform">
                <input
                  type="text"
                  placeholder="Name"
                  required
                  className=" mb-3 form-input"
                  value={name}
                  pattern="[A-Za-z\s]{2,}"
                  onChange={(e) => {
                    setName(e.target.value);
                    e.target.setCustomValidity("");
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Name should have at least 2 characters and contain only alphabets."
                    )
                  }
                />
                <input
                  type="number"
                  placeholder="Phone / WhatsApp number"
                  required
                  className=" mb-3 form-input"
                  value={phoneNumber}
                  onChange={(e) => {
                    const phoneNumber = e.target.value.slice(0, 12);
                    setPhoneNumber(phoneNumber);

                    if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                      e.target.setCustomValidity("");
                    } else {
                      e.target.setCustomValidity(
                        "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                      );
                    }
                  }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                    )
                  }
                />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  className=" mb-3 form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="city"
                  placeholder="City"
                  required
                  className="w-100 mb-3 form-input"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <select
                  name=""
                  required
                  className="  mb-3 form-input"
                  onChange={(e) => setModeOfPurchase(e.target.value)}
                  id=""
                >
                  <option selected disabled style={{ color: "grey" }}>
                    What is your preferred mode of purchase?
                  </option>
                  <option value="Buy Online">Buy Online</option>
                  <option value="Book a Test Ride">Book a Test Ride</option>
                </select>
                <select
                  name=""
                  required
                  className="mb-3 form-input"
                  onChange={(e) => setUseCase(e.target.value)}
                  id=""
                >
                  <option selected disabled>
                    What do you need it for?
                  </option>
                  <option value="Daily Commute">Daily Commute</option>
                  <option value="Fitness">Fitness</option>
                  <option value="For Kids">For Kids</option>
                </select>
                <select
                  name=""
                  required
                  className="mb-3 form-input"
                  onChange={(e) => setBudget(e.target.value)}
                  id=""
                >
                  <option selected disabled>
                    What is your budget?{" "}
                  </option>
                  <option value="Rs 25000 - 35000">Rs 25000 - 35000</option>
                  <option value="Rs 35000 - 50000">Rs 35000 - 50000</option>
                  <option value="More than 50000">More than 50000</option>
                </select>
                <input
                  type="text"
                  placeholder="What is your preferred Language?"
                  className=" mb-3 form-input"
                  value={language}
                  required
                  onChange={(e) => setLanguage(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="btn submit-btn-lg mt-lg-2 mb-lg-3 btn-block w-100 "
              >
                Book Your Test Ride Now
              </button>
              <small className="text-center">
                By choosing to confirm, you consent to share your personal
                information with EMotorad. Terms and Conditions applied.
              </small>
            </form>
          </div>
        </div>
        {/* mobile form */}
        <div className=" p-3 d-md-none">
          <form
            onSubmit={submit}
            className="p-3  landingpage-form-div-main mt-4 "
          >
            <h6
              className=""
              style={{
                fontWeight: "600",
                fontSize: "1.1em",
              }}
            >
              Get up to 20% off on EMotorad electric cycles*
            </h6>
            <div className="mt-4" id="landingform">
              <input
                type="text"
                placeholder="Name"
                required
                className=" mb-3 form-input"
                value={name}
                pattern="[A-Za-z\s]{2,}"
                onChange={(e) => {
                  setName(e.target.value);
                  e.target.setCustomValidity("");
                }}
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Name should have at least 2 characters and contain only alphabets."
                  )
                }
              />
              <input
                type="number"
                placeholder="Phone / WhatsApp number"
                required
                className=" mb-3 form-input"
                value={phoneNumber}
                onChange={(e) => {
                  const phoneNumber = e.target.value.slice(0, 12);
                  setPhoneNumber(phoneNumber);

                  if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                    e.target.setCustomValidity("");
                  } else {
                    e.target.setCustomValidity(
                      "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                    );
                  }
                }}
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  )
                }
              />
              <input
                type="email"
                placeholder="Email"
                required
                className=" mb-3 form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="city"
                placeholder="City"
                required
                className="w-100 mb-3 form-input"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <select
                name=""
                required
                className="  mb-3 form-input"
                onChange={(e) => setModeOfPurchase(e.target.value)}
                id=""
              >
                <option selected disabled style={{ color: "grey" }}>
                  What is your preferred mode of purchase?
                </option>
                <option value="Buy Online">Buy Online</option>
                <option value="Book a Test Ride">Book a Test Ride</option>
              </select>
              <select
                name=""
                required
                className="mb-3 form-input"
                onChange={(e) => setUseCase(e.target.value)}
                id=""
              >
                <option selected disabled>
                  What do you need it for?
                </option>
                <option value="Daily Commute">Daily Commute</option>
                <option value="Fitness">Fitness</option>
                <option value="For Kids">For Kids</option>
              </select>
              <select
                name=""
                required
                className="mb-3 form-input"
                onChange={(e) => setBudget(e.target.value)}
                id=""
              >
                <option selected disabled>
                  What is your budget?{" "}
                </option>
                <option value="Rs 25000 - 35000">Rs 25000 - 35000</option>
                <option value="Rs 35000 - 50000">Rs 35000 - 50000</option>
                <option value="More than 50000">More than 50000</option>
              </select>
              <input
                type="text"
                placeholder="What is your preferred Language?"
                className=" mb-3 form-input"
                value={language}
                required
                onChange={(e) => setLanguage(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="btn submit-btn-lg mt-lg-4 mt-3 mb-lg-3 btn-block w-100 "
            >
              Book Your Test Ride Now
            </button>
            <small className="text-center mt-4">
              By choosing to confirm, you consent to share your personal
              information with EMotorad. Terms and Conditions applied.
            </small>
          </form>
        </div>
        <Splide
          className=" p-0 align-items-center"
          options={{
            arrows: true,
            drag: true,
            type: "loop",
            pagination: true,
            pauseOnHover: true,
            autoplay: true,
            perMove: 1,
            gap: "2em",
            padding: "0em",
            resetProgress: false,
            interval: 3000,
            perPage: 1,
            speed: 1000,
            breakpoints: {
              700: {
                perPage: 1,
                arrows: false,
                pagination: true,
                autoplay: true,
                perMove: 1,
                interval: 5000,
                gap: "0em",
                padding: "0em",
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 500,
              },
            },
          }}
        >
          <SplideSlide>
            <div id="x-factor">
              <picture>
                <source
                  media="(max-width:650px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/X1+Mobile.png"
                />
                <source
                  media="(min-width:651px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/x1+desktop.png"
                />
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/x1+desktop.png"
                  className="img-fluid w-100 rounded-3"
                  alt=""
                  srcSet=""
                />
              </picture>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div id="desire">
              <picture>
                <source
                  media="(max-width:650px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/EMX%2B+mobile.png"
                />
                <source
                  media="(min-width:651px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/EMX+%2B+desktop.png"
                />
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/EMX+%2B+desktop.png"
                  className="img-fluid w-100 rounded-3"
                  alt=""
                  srcSet=""
                />
              </picture>
            </div>
          </SplideSlide>
        </Splide>

        <div className="reviews-div  p-lg-5 p-4">
          <div className="d-flex mb-lg-4 reviews-sub-div">
            <div className="col-lg-9 col-8">
              <h2 className="mb-4 structure-main-heading">Reviews & Ratings</h2>{" "}
              <p className="product-text">
                More than 90% of our customers who have bought EMotorad products
                have loved them. Do take a look at what some of them have to
                say:
              </p>
              <div className="row mt-5 reviews-logo-sec">
                <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                  <img
                    className="reviews-logo"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/amazon-logo_2.png"
                    alt="Amazon Logo"
                  />
                  <div className="d-flex flex-column align-items-center reviews-logo-div">
                    <div className="d-flex">
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                    </div>
                    <p
                      style={{ margin: 0, padding: 0 }}
                      className="review-logo-text"
                    >
                      900+ reviews
                    </p>
                  </div>
                </div>
                <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                  <img
                    className="reviews-logo"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/flipkart-logo1.png"
                    alt="Amazon Logo"
                  />
                  <div className="d-flex flex-column align-items-center reviews-logo-div">
                    <div className="d-flex">
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                    </div>
                    <p
                      style={{ margin: 0, padding: 0 }}
                      className="review-logo-text"
                    >
                      300+ reviews
                    </p>
                  </div>
                </div>
                <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                  <img
                    className="reviews-logo"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Google__G__logo.png"
                    alt="Amazon Logo"
                  />
                  <div className="d-flex flex-column align-items-center reviews-logo-div">
                    <div className="d-flex">
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                      <Icon icon="emojione:star" className="star-logo" />
                    </div>
                    <p
                      style={{ margin: 0, padding: 0 }}
                      className="review-logo-text"
                    >
                      1200+ reviews
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-4" style={{ textAlign: "center" }}>
              <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                <span style={{ fontSize: "2em" }}>4.8</span>
                /5
              </p>
              <Icon icon="emojione:star" />
              <Icon icon="emojione:star" />
              <Icon icon="emojione:star" />
              <Icon icon="emojione:star" />
              <Icon icon="emojione:star" />
              <p className="reviews-text">1500+ reviews</p>
            </div>
          </div>
          <div className="row d-lg-none">
            <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
              <img
                className="reviews-logo"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/amazon-logo_2.png"
                alt="Amazon Logo"
              />
              <div className="d-flex flex-column align-items-center reviews-logo-div">
                <div className="d-flex">
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                </div>
                <p
                  style={{ margin: 0, padding: 0 }}
                  className="review-logo-text"
                >
                  900+ reviews
                </p>
              </div>
            </div>
            <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
              <img
                className="reviews-logo"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/flipkart-logo1.png"
                alt="Amazon Logo"
              />
              <div className="d-flex flex-column align-items-center reviews-logo-div">
                <div className="d-flex">
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                </div>
                <p
                  style={{ margin: 0, padding: 0 }}
                  className="review-logo-text"
                >
                  300+ reviews
                </p>
              </div>
            </div>
            <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
              <img
                className="reviews-logo"
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Google__G__logo.png"
                alt="Amazon Logo"
              />
              <div className="d-flex flex-column align-items-center reviews-logo-div">
                <div className="d-flex">
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                  <Icon icon="emojione:star" className="star-logo" />
                </div>
                <p
                  style={{ margin: 0, padding: 0 }}
                  className="review-logo-text"
                >
                  1200+ reviews
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4  " style={{ background: "#000" }}>
          <div className="container">
            <div className="conatiner-fluid mt-4 mb-4">
              <div className="row riding-cost align-items-center">
                <div className="col-lg-6 ps-lg-5 text-light">
                  <h2>
                    Rising costs? <br /> No sweat
                  </h2>
                  <h4 className="mt-lg-5 mt-4">Save ₹3,710/m on fuel cost</h4>
                  <p>
                    Spend only 7 paise per/km with EMotorad electric cycles. 
                  </p>
                  <h4 className="mt-lg-5 mt-4">No cost EMI</h4>
                  <p>Pay just Rs.1200 per month for EMotorad electric cycles</p>
                  <h4 className="mt-lg-5 mt-4">0 maintenance cost</h4>
                  <p>Cost savings on repairs, replacements, or upkeep.</p>
                </div>
                <div className="col-lg-6 mt-lg-0 mt-3">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/587x604.jpg"
                    alt=""
                    style={{ borderRadius: "5px" }}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="lifestyle-end" style={{ position: "relative" }}>
          <picture style={{ height: "50%" }}>
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/landing+page+banner+phone.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/landing+page+banner.jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/book-now/landing+page+banner.jpg"
              className="img-fluid w-100 lifestyle-image"
              alt=""
              srcSet=""
            />
          </picture>
          <div className="position-absolute call-icon d-none d-lg-block">
            <a href="https://api.whatsapp.com/send/?phone=918686050590&text=Hi,%20I%20am%20interested%20to%20know%20more%20about%20emotorad%20cycles&app_absent=0">
              <Icon icon="logos:whatsapp-icon" width="2.7em" height="2.7em" />
            </a>
          </div>
          <div className="position-absolute whatsapp-icon d-none d-lg-block">
            <div
              className="rounded-50"
              style={{
                backgroundColor: "#004dac",
                borderRadius: "50%",
                height: "2.7rem",
                width: "2.7rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href="tel:+918686050590 " style={{ textDecoration: "none" }}>
                <Icon
                  icon="ion:call"
                  width="1.5em"
                  height="1.5em"
                  style={{ color: "#ffffff" }}
                />
              </a>
            </div>
          </div>
          <div className="position-absolute call-icon d-lg-none">
            <a href="https://api.whatsapp.com/send/?phone=918686050590&text=Hi,%20I%20am%20interested%20to%20know%20more%20about%20emotorad%20cycles&app_absent=0">
              <Icon icon="logos:whatsapp-icon" width="1.8em" height="1.8em" />
            </a>
          </div>
          <div className="position-absolute whatsapp-icon d-lg-none">
            <div
              className="rounded-50"
              style={{
                backgroundColor: "#004dac",
                borderRadius: "50%",
                height: "1.8rem",
                width: "1.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href="tel:+918686050590 " style={{ textDecoration: "none" }}>
                <Icon
                  icon="ion:call"
                  width="1em"
                  height="1em"
                  style={{ color: "#ffffff" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingTestScreen;
