import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOrderDetailsById } from "../Actions/orderActions";
const MyOrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const orderById = useSelector((state) => state.orderById);
  const { order } = orderById;

  const orderConfirm = useSelector((state) => state.orderConfirm);
  const { orderStatus } = orderConfirm;

  useEffect(() => {
    dispatch(getOrderDetailsById(id));
  }, []);

  const currencyFormat = (num) => {
    return "₹" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div
            className="col-12 p-3 text-light"
            style={{
              backgroundColor: "#1a1a1a",
            }}
          >
            <i
              className="fa-solid text-light fa-arrow-left-long me-4"
              onClick={() => navigate(-1)}
            ></i>{" "}
            Order Details
          </div>

          {order && (
            <div className="container ">
              <h6 className="font-bold mt-4 mb-4">Order ID: {order._id}</h6>

              <div className="row">
                <div className="col-12 col-lg-6">
                  <h6 className="cart-mini-heading">Deliver to:</h6>
                  <div
                    className="billing-details p-3  "
                    style={{
                      background: "#E5E1D8",
                      borderRadius: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                      className="mb-1"
                    >
                      {order.name}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {order.address.addressLine1} {order.address.addressLine2},{" "}
                      {order.address.city}, {order.address.state}{" "}
                      {order.address.pincode}
                    </p>
                    <p style={{ fontSize: "14px" }} className="mb-0">
                      {order.phoneNumber}
                    </p>
                  </div>
                  <div className="d-none d-lg-block">
                    <hr className="col-12" />
                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-baseline">
                        <p className="cart-mini-heading">Total Items</p>
                        <h6 className="cart-amount">
                          {order.items.reduce((acc, item) => acc + item.qty, 0)}
                        </h6>
                      </div>
                      {order.promoCode && (
                        <div className="d-flex justify-content-between align-items-baseline">
                          <p
                            className="cart-mini-heading font-bold"
                            style={{
                              fontWeight: "900",
                              color: "#10B068",
                            }}
                          >
                            Promocode
                          </p>
                          <h6
                            className="cart-amount "
                            style={{
                              fontWeight: "900",
                              color: "#10B068",
                            }}
                          >
                            {order.promoCode}
                          </h6>
                        </div>
                      )}

                      <div className="d-flex justify-content-between align-items-baseline">
                        <p className="cart-mini-heading">Total</p>
                        <h6 className="cart-amount">
                          {currencyFormat(
                            order.items.reduce(
                              (acc, item) => acc + item.qty * item.price,
                              0
                            )
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 p-lg-4 pt-lg-0 col-lg-6">
                  <p className="mb-0   cart-mini-heading">Items:</p>
                  <div className="  pb-2 mb-2 ">
                    {order.items.map((item, i) => (
                      <>
                        <div className="row shipping-item-info mt-4 mt-lg-0 align-items-center">
                          <div className="col-4 p-2 item-img">
                            <img
                              src={item.cartImages[item.model]}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="col-8 p-3">
                            <div className="mb-3 item-name pb-2">
                              <h5 className="font-mon mb-0 color-dark">
                                {item.name}
                              </h5>
                            </div>
                            <p className="fw-light mb-2 w-100 d-flex justify-content-between ">
                              {" "}
                              <span className="col-7">Color :</span>{" "}
                              <span className="fw-bold col-5">
                                {item.models[item.model].colorName}
                              </span>
                            </p>
                            <p className="fw-light mb-2 w-100 d-flex justify-content-between ">
                              {" "}
                              <span className="col-7">Quantity :</span>{" "}
                              <span className="fw-bold d-flex align-items-center justify-content-between  col-5">
                                {" "}
                                <span>{item.qty}</span>{" "}
                              </span>
                            </p>
                            <p className="fw-light mb-0 w-100 d-flex justify-content-between ">
                              {" "}
                              <span className="col-7">Price :</span>{" "}
                              <span className="fw-bold d-flex align-items-center justify-content-between  col-5">
                                {" "}
                                <span>
                                  {currencyFormat(item.qty * item.price)}
                                </span>{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>

                <hr className="col-12  d-lg-none" />
                <div className="col-12 d-lg-none">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <p className="cart-mini-heading">Total Items</p>
                    <h6 className="cart-amount">
                      {order.items.reduce((acc, item) => acc + item.qty, 0)}
                    </h6>
                  </div>
                  {order.promoCode && (
                    <div className="d-flex justify-content-between align-items-baseline">
                      <p
                        className="cart-mini-heading font-bold"
                        style={{
                          fontWeight: "900",
                          color: "#10B068",
                        }}
                      >
                        Promocode
                      </p>
                      <h6
                        className="cart-amount "
                        style={{
                          fontWeight: "900",
                          color: "#10B068",
                        }}
                      >
                        {order.promoCode}
                      </h6>
                    </div>
                  )}

                  <div className="d-flex justify-content-between align-items-baseline">
                    <p className="cart-mini-heading">Total</p>
                    <h6 className="cart-amount">
                      {currencyFormat(
                        order.items.reduce(
                          (acc, item) => acc + item.qty * item.price,
                          0
                        )
                      )}
                    </h6>
                  </div>
                </div>
                <hr className="col-12" />
                <div className=" orderStatus"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOrderDetails;
