import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAccessories } from "../Actions/bikeActions";
import { getBikeByName } from "../Actions/bikeActions.js";
import { useParams } from "react-router-dom";
// import logo from "../images/logo1.png";
import { addToCart } from "../Actions/cartActions";
import Navbar from "../Components/Navbar.js";
import { useNavigate, Link } from "react-router-dom";
import Meta from "../Components/Meta.js";
// import ImageGallery from "react-image-gallery";
import { getloginUserInfo } from "../Actions/userAction";
import Footer from "../Components/Footer.js";
import "../CSS/product.css";
import WhatsAppBot from "../Components/WhatsAppBot.js";
import CallBtn from "../Components/CallBtn";
import "@splidejs/splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
const AccessoriesScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productName } = useParams();
  // const [fullScreenClose, setfullScreenClose] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bikeByName = useSelector((state) => state.bikeByName);
  const { bike: productInfo } = bikeByName;

  // const getUserInfo = useSelector((state) => state.getUserInfo);
  // const { userInfo: userDetails } = getUserInfo;

  const [model, setModel] = useState(0);

  const [imageIndex, setImageIndex] = useState(0);

  // const allAccessories = useSelector((state) => state.allAccessories);
  // const { accessories } = allAccessories;

  useEffect(() => {
    dispatch(getAllAccessories());
  }, []);

  useEffect(() => {
    if (productName) {
      dispatch(getBikeByName(productName));
      setImageIndex(0);
    }
  }, [dispatch, productName, userInfo]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getloginUserInfo(userInfo._id));
    }
  }, []);

  const currencyFormat = (num) => {
    return "₹" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const addCart = () => {
    dispatch(addToCart(productInfo._id, model, 1, 1, variant));
    navigate("/cart");
  };

  const meta = {
    beam: {
      title: "Buy Cycle Beam Light at Best Price | EMotorad ",
      description:
        "Upgrade your cycling experience with our premium Cycle Beam Lights. Find the perfect light at the best price",
      canonicalTag: "https://www.emotorad.com/accessories/beam",
    },
    ray: {
      title: "Buy Ray Light | Buy EMotorad Headlight ",
      description:
        "Illuminate your ride with premium Ray Light headlights – perfect for enhancing visibility and safety on your EMotorad bike.",
      canonicalTag: "https://www.emotorad.com/accessories/ray",
    },
    photon: {
      title: "Photon LED Headlight | Lights for Bicycles",
      description:
        "Illuminate your ride with the Photon LED Headlight - the ultimate lighting solution for every cyclist. Experience unmatched visibility and safety on your biking adventures.",
      canonicalTag: "https://www.emotorad.com/accessories/photon",
    },
    "emetric-lock": {
      title: "Buy Cycle Smart Lock, EMotorad Defender Smart Lock Bicycles",
      h1: "Buy Cycle Smart Lock Online",
      description:
        "Experience the future of bicycle security with EMotorad Defender Smart Lock. Advanced, reliable, and easy to use - your bike's best defense",
      canonicalTag: "https://www.emotorad.com/accessories/emetric-lock",
    },
  };

  const metaData = {};

  for (const key in meta) {
    if (meta.hasOwnProperty(key) && key == productName) {
      metaData["product"] = meta[key];
    }
  }

  const productSpecs = [
    {
      productName: "force-xtech", // yellow helmet
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/yellow-helmet.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Beacon+helmet/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Beacon+helmet/Mobile/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Beacon+helmet/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Beacon+helmet/Mobile/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Beacon+helmet/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Beacon+helmet/Mobile/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Material",
          value: "ABS, PC+EPS",
        },
        {
          name: "Weight",
          value: "360 gm",
        },
        {
          name: "Color",
          value: "Neon Yellow",
        },
        {
          name: "Feature",
          value:
            "Ultralight Breathable LED headlight and rearlight Cycling Helmet",
        },
      ],
    },
    {
      productName: "rebel-xt", // red helmet
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/helmet-red.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Slipstream/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Slipstream/Mobile/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Slipstream/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Slipstream/Mobile/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Slipstream/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Slipstream/Mobile/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Material",
          value: "ABS, PC+EPS",
        },
        {
          name: "Weight",
          value: "300 gm",
        },
        {
          name: "Feature",
          value: "Ultralight Breathable Cycling Helmet",
        },
        {
          name: "Certification",
          value: "CE/CPC/CPSC-16 CFR 1203",
        },
      ],
    },

    {
      productName: "nrg-pro", // bag
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/bag1.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Journeyman/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Journeyman/Desktop/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Journeyman/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Journeyman/Desktop/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Journeyman/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Journeyman/Desktop/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Material",
          value: "Polyester",
        },
        {
          name: "Size",
          value: "44 x 15 x 31cm",
        },
        {
          name: "Feature",
          value: "with USB, Waterproof",
        },
        {
          name: "Gender",
          value: "Unisex",
        },
      ],
    },
    {
      productName: "emetric-lock",
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/fingerprintlock.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Defender+SmartLock/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Defender+SmartLock/Desktop/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Defender+SmartLock/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Defender+SmartLock/Desktop/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Defender+SmartLock/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Defender+SmartLock/Desktop/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Material",
          value: "Alloy/PVC/Steel Cable",
        },
        {
          name: "Waterproof protection",
          value: "IP65 ",
        },
        {
          name: "Standby Time ",
          value: "6 months",
        },
        {
          name: "Charging time",
          value: "3 hours",
        },
      ],
    },
    {
      productName: "fortknox-lock",
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/numpadlock.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/FortKnox+Lock/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/FortKnox+Lock/Desktop/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/FortKnox+Lock/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/FortKnox+Lock/Desktop/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/FortKnox+Lock/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/FortKnox+Lock/Desktop/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Material",
          value: "PVC",
        },
        {
          name: "Color",
          value: "Black",
        },
        {
          name: "Weight",
          value: "0.12kg",
        },
        {
          name: "Lock Type",
          value: "Combination Lock",
        },
      ],
    },
    {
      productName: "claw-mount",
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/mobileholder1.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Rivet+360/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Rivet+360/Desktop/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Rivet+360/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Rivet+360/Desktop/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Rivet+360/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/Rivet+360/Desktop/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Material",
          value: "Silicone",
        },
        {
          name: "Feature",
          value: "360 Degree Rotation + Adjustable",
        },
        {
          name: "Size Supported",
          value: "4.5 - 6.8 inch",
        },
        {
          name: "Color",
          value: " Black",
        },
      ],
    },
    {
      productName: "miniair",
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/mechanical-pump.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/MiniAir/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/MiniAir/Desktop/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/MiniAir/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/MiniAir/Desktop/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/MiniAir/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/MiniAir/Desktop/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Material",
          value: "Aluminium",
        },
        {
          name: "Size",
          value: "Medium",
        },
        {
          name: "Item Weight",
          value: "480 gm",
        },
        {
          name: "Dimensions",
          value: `10.5 x 4.5" `,
        },
      ],
    },
    {
      productName: "smartair",
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/pump-electric.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Voltage",
          value: "7.4v",
        },
        {
          name: "Max Pressure",
          value: "101-150 Psi",
        },
        {
          name: "Battery capacity",
          value: " 2000mah*2",
        },
        {
          name: "Max flow",
          value: " 12 L/Min",
        },
      ],
    },
    {
      productName: "driveair",
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/pump-2/Pump2.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/AirPlus/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/AirPlus/Desktop/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/AirPlus/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/AirPlus/Desktop/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/AirPlus/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/AirPlus/Desktop/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Diameter",
          value: "30mm/35mm",
        },
        {
          name: "Max Pressure",
          value: "101-150 Psi",
        },
        {
          name: "Tube thickness",
          value: "1-1.5mm",
        },
        {
          name: "Base Material",
          value: "Iron and Plastic base",
        },
      ],
    },
    {
      productName: "cleaning-kit",
      box: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/accessories/accessories-box/pump-electric.png",
      gallery: [
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+1.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+1.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+2.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+2.jpg",
        ],
        [
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+3.jpg",
          "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/Single+accessories+page/SmartAir/Desktop/Lifestyle/Artboard+3.jpg",
        ],
      ],
      specs: [
        {
          name: "Voltage",
          value: "7.4v",
        },
        {
          name: "Max Pressure",
          value: "101-150 Psi",
        },
        {
          name: "Battery capacity",
          value: " 2000mah*2",
        },
        {
          name: "Max flow",
          value: " 12 L/Min",
        },
      ],
    },
  ];
  // const ind = useRef(0);
  const [variant, setVariant] = useState(0);

  const productSpecsData = productSpecs.find((p) => p.name === productName);
  const title =
    metaData && Object.keys(metaData).length !== 0
      ? metaData.product.title
      : null;
  const description =
    metaData && Object.keys(metaData).length !== 0
      ? metaData.product.description
      : null;
  const cannonicalTag =
    metaData && Object.keys(metaData).length !== 0
      ? metaData.product.canonicalTag
      : null;
  const h1 =
    metaData && Object.keys(metaData).length !== 0 ? metaData.product.h1 : null;
  return (
    // {name=== "beacon"?<></>:}
    <>
      {productInfo && (
        <Meta
          title={
            title ||
            `Buy ${productInfo.name} ${productInfo.subCategory} at best price | EMotorad`
          }
          description={description || `${productInfo.description}`}
          cannonicalTag={
            cannonicalTag ||
            `https://www.emotorad.com/accessories/${productInfo.urlName}`
          }
        />
      )}
      {/* <WhatsAppBot bottom={"12%"} /> */}
      <Navbar />
      <CallBtn bottom={"18%"} />
      {productInfo && (
        <>
          {" "}
          <div className="container mt-lg-5 mt-1 mb-5">
            <div className="row">
              <div className="col-lg-8 p-0  ">
                <div className="position-relative p-lg-0 p-2">
                  <img
                    src={productInfo.productImages[model][imageIndex]}
                    className="w-100 bike-image  "
                    style={{
                      background: "#F2F5F5",
                    }}
                    alt=""
                  />
                  <div className="product-images ps-0 pe-0  mt-lg-2 d-flex  ">
                    {productInfo &&
                      productInfo.productImages[model].map((image, i) => (
                        <div
                          key={i}
                          onClick={() => setImageIndex(i)}
                          style={{
                            width: "20%",
                          }}
                          className="p-2 ps-0 pe-0"
                        >
                          <img
                            src={image}
                            style={{
                              cursor: "pointer",
                              background: "#F2F5F5",
                            }}
                            className={`img-fluid ${
                              i === imageIndex && "border"
                            }  w-75  p-2 bg-product-images mx-auto d-block `}
                            alt=""
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>{" "}
              <div className="col-lg-4">
                <div className="bike-info ps-lg-5">
                  {h1 && (
                    <h1
                      className="mt-3 mt-lg-0"
                      style={{
                        fontFamily: "Metropolis-medium",
                        fontSize: "1em",
                      }}
                    >
                      {h1}
                    </h1>
                  )}
                  <h1 className="fw-bold mt-4">{productInfo.name}</h1>

                  <p className="mt-3 bike-desc text-muted">
                    {productInfo.description}
                  </p>
                  <div
                    className="d-flex align-items-bottom"
                    style={{ gap: "0.3em" }}
                  >
                    <i
                      className="fa-solid   fa-star "
                      style={{
                        color: "#FFC700",
                        fontSize: "1.2em",
                      }}
                    ></i>
                    <i
                      className="fa-solid   fa-star "
                      style={{
                        color: "#FFC700",
                        fontSize: "1.2em",
                      }}
                    ></i>
                    <i
                      className="fa-solid   fa-star "
                      style={{
                        color: "#FFC700",
                        fontSize: "1.2em",
                      }}
                    ></i>
                    <i
                      className="fa-solid   fa-star "
                      style={{
                        color: "#FFC700",
                        fontSize: "1.2em",
                      }}
                    ></i>
                    <i
                      className="fa-solid   fa-star "
                      style={{
                        color: "#FFC700",
                        fontSize: "1.2em",
                      }}
                    ></i>
                    <p
                      className="m-0"
                      style={{
                        fontSize: "0.9em",
                        color: "#999999",
                      }}
                    >
                      (153 Reviews)
                    </p>
                  </div>
                  <div
                    className="d-flex mt-lg-4 mt-3 product-card-price align-items-center "
                    style={{ gap: "1em" }}
                  >
                    <p
                      className="bike-price fw-bold"
                      style={{ fontSize: "1.3em" }}
                    >
                      {currencyFormat(
                        productInfo.variant
                          ? productInfo.variant[variant].price
                          : productInfo.price
                      )}
                    </p>
                    {productInfo.slashPrice > 0 && (
                      <p
                        className="slash-bike-price fw-bold"
                        style={{
                          fontSize: "1.6em",
                          textDecoration: "line-through",
                        }}
                      >
                        {currencyFormat(productInfo.slashPrice)}
                      </p>
                    )}
                    {productInfo.slashPrice > 0 && (
                      <p
                        className="save-percent-product-screen fw-bold"
                        style={{
                          color: "#00cf13",
                        }}
                      >
                        save &nbsp;
                        {Math.trunc(
                          ((productInfo.slashPrice -
                            (productInfo.variant
                              ? productInfo.variant[variant].price
                              : productInfo.price)) /
                            productInfo.slashPrice) *
                            100
                        )}
                        {"%"}
                      </p>
                    )}
                  </div>

                  <small className="including-gst">Including GST</small>

                  {productInfo.variant && productInfo.variant.length > 0 && (
                    <>
                      <div className="bike-colors mt-4 ">
                        <p className="mb-2 mb-lg-3">
                          {productInfo.variant[0].variant_Title} -{" "}
                          {productInfo.variant[variant].variant_name}
                        </p>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{
                          gap: "1.2em",
                        }}
                      >
                        {productInfo.variant.map((item, i) => (
                          <div
                            key={i}
                            className={`w-100 p-1 ${
                              i === variant && "variant-border"
                            }`}
                          >
                            <div
                              style={{
                                background: "#F2F5F5",
                              }}
                              className=" w-100 text-center text-dark p-2"
                              onClick={() => setVariant(i)}
                            >
                              {item.variant_name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  <div className="mt-3 d-md-block">
                    {productInfo.inStock > 0
                      ? productInfo.variant && (
                          <div>
                            {productInfo.variant[variant].inStock ? (
                              <>
                                <button
                                  className="p-3 btn d-block mx-auto  mx-lg-0 mt-lg-4 home-cta "
                                  style={{
                                    borderRadius: "25px",
                                    width: "240px",
                                  }}
                                  onClick={() =>
                                    addCart(
                                      productInfo._id,
                                      model,
                                      1,
                                      1,
                                      variant
                                    )
                                  }
                                >
                                  BUY NOW
                                </button>
                              </>
                            ) : (
                              <button className="p-3 btn  btn-dark disabled w-100 mt-lg-4  rounded-0">
                                OUT OF STOCK
                              </button>
                            )}
                          </div>
                        )
                      : ""}

                    {!productInfo.variant && (
                      <div>
                        {productInfo.inStock > 0 ? (
                          <>
                            <button
                              className="p-3 btn  d-block mx-auto mx-lg-0  mt-lg-4 home-cta "
                              style={{
                                borderRadius: "25px",
                                width: "240px",
                              }}
                              onClick={() =>
                                addCart(productInfo._id, model, 1, 1, variant)
                              }
                            >
                              BUY NOW
                            </button>
                          </>
                        ) : (
                          <button className="p-3 btn  btn-dark disabled w-100 mt-lg-4  rounded-0">
                            OUT OF STOCK
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {productSpecsData?.gallery && (
            <Splide
              className=" flex p-1 p-lg-4 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "loop",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "1em",
                pauseOnHover: false,
                resetProgress: false,
                interval: 1000,

                perPage: 3,

                speed: 500,
                breakpoints: {
                  640: {
                    perPage: 1,
                    arrows: true,
                    pagination: true,
                    autoplay: false,
                    perMove: 1,
                    gap: "2em",

                    pauseOnHover: false,
                    resetProgress: false,
                    interval: 1000,
                    focus: 0,
                    perPage: 1,
                    start: 0,

                    speed: 500,
                  },
                },
              }}
            >
              {productSpecsData.gallery.map((item, i) => (
                <SplideSlide key={i} className=" ">
                  <div>
                    <picture>
                      <source media="(max-width:650px)" srcSet={item[1]} />
                      <source media="(min-width:651px)" srcSet={item[0]} />
                      <img
                        src={item[0]}
                        className="img-fluid w-100"
                        alt=""
                        srcSet=""
                      />
                    </picture>
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          )}
          <Footer />{" "}
        </>
      )}
    </>
  );
};

export default AccessoriesScreen;
