import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import React from "react";

const ScrollToHash = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const offset = window.innerHeight * 0.1; // 10% of the window height
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY; // Replaced pageYOffset with scrollY
        const scrollPosition = elementPosition - offset;
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [hash]);

  return null;
};

export default ScrollToHash;
