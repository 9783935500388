import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBikeByName, getAllBikes } from "../Actions/bikeActions.js";
import { useParams, useLocation, Link } from "react-router-dom";
import Tooltip from "../Components/Tooltip.js";
import { addToCart } from "../Actions/cartActions.js";
import Navbar from "../Components/Navbar.js";
import { useNavigate } from "react-router-dom";
import Meta from "../Components/Meta.js";
import Footer from "../Components/Footer.js";
import "../CSS/product.css";
import "../CSS/mobproduct.css";
import ImageGallery from "react-image-gallery";
import CallBtn from "../Components/CallBtn.js";
import { Icon } from "@iconify/react";
import { Swiper, SwiperSlide } from "swiper/react";
import Loder from "../Components/Loder.js";
import { bikeHighlights } from "../Components/bikeDetailsObject.js";
import { FAQs } from "../Components/bikeFAFObject.js";
import "@splidejs/splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import VideoModal from "../Components/VideoModal.js";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import TooltipMobile from "../Components/TooltipMobile.js";
import { bikeByNameReducer } from "../Reducers/bikeReducers.js";
import { GET_BIKE_BY_NAME_REQUEST } from "../Constants/bikeConstants.js";
import { addToPreBuy } from "../Actions/prebookAction.js";
import ScrollToTop from "../Components/ScrollToTop.js";

const ProductScreen = () => {
  const countDownDate = new Date("June 01, 2024 00:00").getTime();
  // const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTimeRemaining(calculateTimeRemaining());
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);
  // function calculateTimeRemaining() {
  //   const now = new Date().getTime();
  //   const distance = countDownDate - now;

  //   if (distance < 0) {
  //     clearInterval();
  //     return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  //   }

  //   const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor(
  //     (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   );
  //   const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //   const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //   return { days, hours, minutes, seconds };
  // }
  SwiperCore.use([Navigation, Pagination, Scrollbar]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useParams();

  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;

  const ind = useRef(0);

  const bikeStatsGallery = useRef(0);

  const [showModel, setShowModel] = useState(false);

  const [popupAcc, setPopupAcc] = useState();

  const [fullScreenClose, setfullScreenClose] = useState(false);

  const [Collapse, setCollapse] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bikeByName = useSelector((state) => state.bikeByName);
  const { bike: bikeInfo } = bikeByName;

  const [variant, setVariant] = useState(0);

  const [model, setModel] = useState(0);

  const [imageIndex, setImageIndex] = useState(1);

  const sliderRef = useRef();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(""); // State to hold the video URL

  const openModal = (url) => {
    setVideoUrl(url); // Set the video URL dynamically
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  // useEffect(() => {
  //   if (allBikes && bikes && bikes.length > 0) {
  //     console.log(bikes.filter((item) => item.urlName === name));
  //     dispatch({
  //       type: GET_BIKE_BY_NAME_REQUEST,
  //       payload: bikes.filter((item) => item.urlName === name)[0],
  //     });
  //   }
  // }, []);

  const [isDimensionOpen, setIsDimensionOpen] = useState(true);
  const [isDescOpen, setIsDescOpen] = useState(false);
  const [isTechnicalDetailsOpen, setIsTechnicalDetailsOpen] = useState(false);
  const [isShippingOpen, setIsShippingOpen] = useState(false);
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [isDoubtsOne, setIsDoubtsOne] = useState(false);
  const [isDoubtsTwo, setIsDoubtsTwo] = useState(false);
  const [isDoubtsThree, setIsDoubtsThree] = useState(false);
  const [isDoubtsFour, setIsDoubtsFour] = useState(false);
  const [isDoubtsFive, setIsDoubtsFive] = useState(false);
  const [bike1, setBike1] = useState("");
  const [bike2, setBike2] = useState("");

  const toggleDimensionCollapse = () => {
    setIsDimensionOpen(!isDimensionOpen);
  };
  const toggleTechnicalDetailsCollapse = () => {
    setIsTechnicalDetailsOpen(!isTechnicalDetailsOpen);
  };
  const toggleDescCollapse = () => {
    setIsDescOpen(!isDescOpen);
  };
  const toggleShippingCollapse = () => {
    setIsShippingOpen(!isShippingOpen);
  };
  const toggleFaqCollapse = () => {
    setIsFaqOpen(!isFaqOpen);
  };
  const toggleDoubtsOneCollapse = () => {
    setIsDoubtsOne(!isDoubtsOne);
  };
  const toggleDoubtsTwoCollapse = () => {
    setIsDoubtsTwo(!isDoubtsTwo);
  };
  const toggleDoubtsThreeCollapse = () => {
    setIsDoubtsThree(!isDoubtsThree);
  };
  const toggleDoubtsFourCollapse = () => {
    setIsDoubtsFour(!isDoubtsFour);
  };
  const toggleDoubtsFiveCollapse = () => {
    setIsDoubtsFive(!isDoubtsFive);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (name) {
        await dispatch(getBikeByName(name));
        setImageIndex(1);
        setShowModel(false);
        if (bikeInfo) {
          if (bikeInfo.models.length > params.get("color")) {
            setModel(Number(params.get("color")));
          }
        }
      }
    };
    fetchData();
  }, [dispatch, name, userInfo, allBikes]);

  useEffect(() => {
    if (bikeInfo) {
      if (bikeInfo.name === "X1 Limited Edition") {
        setBike1("X1 Ltd Ed");
        setBike2("T-Rex Air");
      } else {
        setBike1(bikeInfo.name);
      }
      if (bikeInfo.name === "Legend 07 MSD Edition") {
        setBike1("Legend 07");
        setBike2("T-Rex Air");
      }
      if (bikeInfo.name === "X1") {
        setBike2("X2");
      }
      if (bikeInfo.name === "X2") {
        setBike2("T-Rex Air");
      }
      if (bikeInfo.name === "X3") {
        setBike2("T-Rex Air");
      }
      if (bikeInfo.name === "T-Rex+ V3") {
        setBike2("T-Rex Pro");
      }
      if (bikeInfo.name === "T-Rex Pro") {
        setBike2("EMX+");
      }
      if (bikeInfo.name === "T-Rex Air") {
        setBike2("T-Rex+ V3");
      }
      if (bikeInfo.name === "Neo") {
        setBike2("T-Rex Air");
      }
      if (bikeInfo.name === "EMX") {
        setBike2("EMX+");
      }
      if (bikeInfo.name === "EMX+") {
        setBike2("T-Rex Pro");
      }
      if (bikeInfo.name === "Doodle V2") {
        setBike2("Doodle V3");
      }
      if (bikeInfo.name === "Doodle V3") {
        setBike2("T-Rex Pro");
      }
      if (bikeInfo.name === "Doodle Pro") {
        setBike2("Doodle V3");
      }
      if (bikeInfo.name === "ST-X") {
        setBike2("X1");
      }
      if (bikeInfo.name === "Lil E") {
        setModel(0);
      } else {
        setModel(0);
      }
    }

    const handleViewItem = () => {
      if (bikeInfo && bikeInfo.name) {
        window.gtag("event", "view_item", {
          currency: "INR",
          value: bikeInfo.accPrice,
          items: [
            {
              item_id: bikeInfo._id,
              item_name: bikeInfo.name,
              item_brand: "EMotorad",
              item_category: "E-Bikes",
              item_category2: bikeInfo.subCategory,
              item_list_name: bikeInfo.subCategory,
              item_variant: `${bikeInfo.models[model].colorName} ${
                bikeInfo.variants.length
                  ? `|${bikeInfo.variants[variant]?.variantName}`
                  : ""
              }`,
              price: bikeInfo.accPrice,
              quantity: 1,
            },
          ],
        });
      }
    };
    const createProductSchema = () => {
      if (bikeInfo && bikeInfo.name) {
        const productSchema = {
          "@context": "http://schema.org",
          "@type": "Product",
          name: bikeInfo.name,
          url: `https://www.emotorad.com/bikes/${name}`,
          image: bikeInfo.cartImages[0],
          description: bikeInfo.description,
          brand: {
            "@type": "Brand",
            name: "EMotorad",
          },
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: bikeInfo.reviews.length
              ? parseFloat(bikeInfo.reviews[0])
              : 4.6,
            reviewCount: bikeInfo.reviews.length
              ? parseInt(bikeInfo.reviews[1])
              : 149,
          },
          offers: {
            "@type": "Offer",
            priceCurrency: "INR",
            price: bikeInfo.accPrice,
            itemCondition: "NewCondition",
            availability: bikeInfo.inStock ? "InStock" : "Out of Stock",
          },
        };
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.innerHTML = JSON.stringify(productSchema);

        document.head.appendChild(script);
      }
    };
    createProductSchema();
    handleViewItem();
  }, [bikeInfo, name]);

  const currencyFormat = (num) => {
    if (typeof num !== "number") {
      return "₹ 0"; // Fallback in case of non-numeric input
    }
    return "₹" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const addCart = () => {
    const handleAddToCart = () => {
      if (bikeInfo) {
        window.gtag("event", "add_to_cart", {
          currency: "INR",
          value: bikeInfo.accPrice,
          items: [
            {
              item_id: bikeInfo._id,
              item_name: bikeInfo.name,
              item_brand: "EMotorad",
              item_category: "E-Bikes",
              item_category2: bikeInfo.subCategory,
              item_variant: `${bikeInfo.models[model].colorName} ${
                bikeInfo.variants.length
                  ? `|${bikeInfo.variants[variant]?.variantName}`
                  : ""
              }`,
              price: bikeInfo.accPrice,
              quantity: 1,
            },
          ],
        });
      }
    };
    handleAddToCart();
    dispatch(addToCart(bikeInfo._id, model, 1, 1, variant));
    navigate("/cart");
  };

  const addPreBook = () => {
    dispatch(addToPreBuy(bikeInfo._id, model, 1, 1));
    navigate("/pre-book");
  };

  const bikeSpecs = [
    {
      name: "X1",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/x1b.jpg",
      price: currencyFormat(24999),
      frame: "High Tensile Steel Frame",
      gears: "Single Speed",
      range: "Up to 35 KM on PAS | Up to 30 Km on Throttle",
      tyre: '27.5" X 2.1" Nylon Tyres',
      suspension: "Front Suspension with 100 mm Travel",
      battery: "36V 7.65 Ah Li-Ion Removable Battery",
      display: "Battery Indicator",
      weight: "Up to 110 KG",
      height: '5"3 ft. to 6 ft.',
      warranty: "5 Years",
    },
    {
      name: "Neo",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/cartBot.png",
      price: currencyFormat(25999),
      frame: "High Tensile Steel Frame",
      gears: "Single Speed",
      range: "Up to 35 KM on PAS | Up to 30 Km on Throttle",
      tyre: '27.5" X 2.1" Nylon Tyres',
      suspension: "Front Suspension with 100 mm Travel",
      battery: "36V 7.65 Ah Li-Ion Removable Battery",
      display: "Battery Indicator",
      weight: "Up to 110 KG",
      height: '5"3 ft. to 6 ft.',
      warranty: "5 Years",
    },
    {
      name: "Legend 07",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/x1+legend+card+w.jpg",
      price: currencyFormat(29999),
      frame: "High Tensile Steel Frame",
      gears: "Single Speed",
      range: "Up to 35 KM on PAS | Up to 30 Km on Throttle",
      tyre: '27.5" X 2.1" Nylon Tyres',
      suspension: "Front Suspension with 100 mm Travel",
      battery: "36V 7.65 Ah Li-Ion Removable Battery",
      display: "Cluster C1 Display",
      weight: "Up to 110 KG",
      height: '5"3 ft. to 6 ft.',
      warranty: "5 Years",
    },
    {
      name: "X1 Ltd Ed",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/x1+cherry+red.jpg",
      price: currencyFormat(27999),
      frame: "High Tensile Steel Frame",
      gears: "Single Speed",
      range: "Up to 35 KM on PAS | Up to 30 Km on Throttle",
      tyre: '27.5" X 2.1" Nylon Tyres',
      suspension: "Front Suspension with 100 mm Travel",
      battery: "36V 7.65 Ah Li-Ion Removable Battery",
      display: "Cluster C2 Display",
      weight: "Up to 110 KG",
      height: '5"3 ft. to 6 ft.',
      warranty: "5 Years",
    },
    {
      name: "X2",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/X2-red.jpg",
      price: currencyFormat(27999),
      frame: "High Tensile Step-Through Steel Frame",
      gears: "Single Speed",
      range: "Up to 35 KM on PAS | Up to 30 Km on Throttle",
      tyre: '27.5" X 2.1" Nylon tyres',
      suspension: "Front Suspension with 100 mm Travel",
      battery: "36V 7.65 Ah Li-Ion Removable Battery",
      display: "Cluster C2 Display",
      weight: "Up to 110 KG",
      height: "5 ft. to 6 ft.",
      warranty: "5 Years",
    },
    {
      name: "X3",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/X3+Silver.jpg",
      price: currencyFormat(34999),
      frame: "High Tensile Steel Frame",
      gears: "Single Speed",
      range: "Up to 40 KM on PAS | Up to 30 Km on Throttle",
      tyre: "700X45C 60 TPI Puncture Resistance upto 3mm",
      suspension: "Front Suspension with 60 mm Travel",
      battery: "36V 7.0Ah Li-Ion Frame Integrated Battery with Panasonic Cells",
      display: "Cluster C3 Colour Display",
      weight: "Up to 110 KG",
      height: "5 ft. to 6 ft.",
      warranty: "5 Years",
    },
    {
      name: "T-Rex Pro",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/T-Rex+pro+purple.jpg",
      price: `Starting ${currencyFormat(52999)}`,
      frame: "Alluminium Alloy 6061 Frame",
      gears: "7 Shimano Altus Gear",
      range: "Up to 70 KM on PAS | Up to 45 KM on Throttle",
      tyre: '27.5" x 2.25 | 29" x 2.25 Nylon Tyres',
      suspension: "100 mm Travel with lockout",
      battery: "36V 13Ah Lithium-ion Removable Battery",
      display: "Cluster i13 Handlebar Integrated Display",
      weight: "upto 110KG",
      height: "5.5 ft. to 5.11 ft.",
      warranty: "Lifetime",
    },
    {
      name: "T-Rex+ V3",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/T-REX+%2B+new.png",
      price: currencyFormat(44999),
      frame: "Alluminium Alloy 6061 Frame",
      gears: "Shimano Altus 7 speed",
      range: "Up to 40 Km on Throttle | Up to 50 Km on PAS",
      tyre: '26" | 27.5" | 29" x 2.25" Nylon Tyres',
      suspension: "Front Suspension with 100 mm Travel with lockout",
      battery: "36V 10.2Ah LI-ion Removable Battery",
      display: "Cluster i8 Stem Integrated Display",
      weight: "upto 110KG",
      height: "5.1 ft. to 5.11 ft.",
      warranty: "Lifetime",
    },
    {
      name: "T-Rex Air",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/T-Rex%2BAir_CherryRed.jpg",
      price: currencyFormat(34999),
      frame: "High Tensile Steel Frame",
      gears: "Shimano TY300 7speed ",
      range: "Up to 40 Km on Throttle | Up to 50 Km on PAS",
      tyre: '27.5" | 29" x 2.4" Nylon Tyres',
      suspension: "Front Suspension with 100 mm Travel",
      battery: "36V 10.2Ah LI-ion Removable Battery",
      display: "Cluster C5 Display",
      weight: "Up to 110 KG",
      height: '5.4" ft. to 6 ft.',
      warranty: "5 Years",
    },
    {
      name: "Doodle V2",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/cards-and-cartimages/doodle.png",
      price: currencyFormat(49999),
      frame: "Foldable Aluminium Alloy 6061 Frame",
      gears: "Shimano Tourney TY300 - 7 Speed Shifter",
      range: "35+ Km on Throttle | 30+ Km on PAS",
      tyre: '20" x 4" Nylon Tyres',
      suspension: "Front Suspension 60mm Travel with lockout",
      battery: "36V 10.4 Ah Li-Ion Removable Battery",
      display: "EMotorad M5 LCD Display with Waterproof Cover",
      weight: "Up to 110 KG",
      height: "5ft. to 6 ft.",
      warranty: "Lifetime",
    },
    {
      name: "Doodle V3",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/doodleNocturnal.jpg",
      price: currencyFormat(52999),
      frame: "Foldable Aluminium Alloy 6061 Frame",
      gears: "Shimano Tourney TZ500 - 7 Speed",
      range: "Up to 45 Km on Throttle | Up to 60 Km on PAS",
      tyre: '20" x 4.0" Nylon Tyres',
      suspension: "Front Suspension with 60mm travel with lockout",
      battery: "36V 12.75 Ah Li-Ion Removable Battery",
      display: "Cluster C6 Display",
      weight: "Up to 110 KG",
      height: "5ft. to 6 ft. ",
      warranty: "Lifetime",
    },
    {
      name: "Doodle Pro",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/cardandcartImages/doodleNocturnal.jpg",
      price: currencyFormat(55999),
      frame: "Foldable Aluminium Alloy 6061 Frame",
      gears: "Shimano Tourney TZ500 - 7 Speed",
      range: "Up to 45 Km on Throttle | Up to 60 Km on PAS",
      tyre: '20" x 4.0" Nylon Tyres',
      suspension: "60mm Travel with lockout Fork",
      battery: "36V 12.75 Ah Li-Ion Removable Battery",
      display: "Cluster C6+ Display",
      weight: "Up to 110 KG",
      height: "5ft. to 6 ft. ",
      warranty: "Lifetime",
    },
    {
      name: "EMX",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/cards-and-cartimages/emx-new.png",
      price: currencyFormat(49999),
      frame: "Alluminium Alloy 6061 Frame",
      gears: "Shimano Tourney TY300  - 21-Speed",
      range: "50+ KM",
      tyre: '27.5" x 2.0" Nylon Tyres',
      suspension:
        "Front Suspension 100mm Travel with Lockout & Rear Suspension with adjustable, 10- 20 mm travel",
      battery: "10.4AH",
      display: "EMotorad M5 LCD Display ",
      weight: "Up to 110 KG",
      height: "5ft. to 6 ft. ",
      warranty: "Lifetime",
    },
    {
      name: "EMX+",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/emx/aqua/1.png",
      price: currencyFormat(49999),
      frame: "Alluminium Alloy 6061 Frame",
      gears: "3*7 Shimano Altus gears, 21 Speed Drivetrain",
      range: "Up to 60 Km on Throttle | Up to 80 Km on PAS",
      tyre: '27.5" x 2.0" Nylon Tyres',
      suspension:
        "Front Suspension 100mm Travel with Lockout & Rear Suspension with adjustable, 10- 20 mm travel",
      battery: "36V 13Ah Li-Ion Removable Battery",
      display: "Cluster C7 Colour Display",
      weight: "Up to 110 KG",
      height: "5ft. to 6 ft. ",
      warranty: "Lifetime",
    },
    {
      name: "ST-X",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/emx/aqua/1.png",
      price: currencyFormat(29999),
      frame: "High Tensile Step-Through Steel Frame",
      gears: "Single Speed",
      range: "35 KM on PAS | 30 Km on Throttle",
      tyre: "700X40C Nylon Tyres",
      suspension: "Front Suspension with 60mm travel",
      battery: "36V 7.65Ah Li-Ion Removable Battery",
      display: "Cluster C2 Display",
      weight: "Up to 110KG",
      height: "4.8 ft. - 5'10ft",
      warranty: "5 years",
    },
  ];
  const tableSpecsData = bikeSpecs.find((b) => b.name === name);
  const bikeHighlightsData = bikeHighlights.find((b) => b.name === name);

  const [imagesLoaded, setImagesLoaded] = useState(false);

  // Track how many images have been loaded
  const handleImageLoad = () => {
    const totalImages =
      bikeInfo.name === "T-Rex Pro" || bikeInfo.name === "Lil E"
        ? bikeInfo.variants[variant]?.models.find((m) => m.modelIndex === model)
            ?.productImages.length
        : bikeInfo?.productImages?.[
            bikeInfo?.variants?.length
              ? bikeInfo?.variants?.[variant]?.models?.[model]
              : model
          ]?.length;

    // Update state once all images are loaded
    if (imageIndex + 1 === totalImages) {
      setImagesLoaded(true);
    }
  };

  // Reset imagesLoaded state when the variant or model changes
  useEffect(() => {
    setImagesLoaded(false);
  }, [variant, model]);

  const [images, setImages] = useState([]);
  const [loadImageComponent, setLoadImageComponent] = useState(false);

  useEffect(() => {
    if (bikeInfo) {
      const getImages = () => {
        if (bikeInfo.name === "T-Rex Pro" || bikeInfo.name === "Lil E") {
          return (
            bikeInfo.variants[variant]?.models.find(
              (m) => m.modelIndex === model
            )?.productImages ?? []
          );
        } else {
          return (
            bikeInfo?.productImages?.[
              bikeInfo?.variants?.length
                ? bikeInfo?.variants[variant]?.models?.[model]
                : model
            ] ?? []
          );
        }
      };

      // Set the updated images
      setImages(getImages());
      setLoadImageComponent(true);
    }
  }, [variant, model, bikeInfo]);

  return (
    <>
      {bikeInfo && bikeHighlightsData && (
        <Meta
          title={bikeHighlightsData.meta.title}
          description={bikeHighlightsData.meta.description}
          product={bikeInfo}
          cannonicalTag={bikeHighlightsData.meta.canonicalTag}
        />
      )}
      {/* <Loder /> */}
      <ScrollToTop />

      <Navbar breadcrumb={true} />
      {/* <CallBtn bottom={"20%"} />
      <WhatsAppBot bottom={"12%"} /> */}
      <VideoModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        videoUrl={videoUrl}
      />

      {bikeInfo && bikeHighlightsData && (
        <>
          <div className="container-fluid position-relative">
            <div className="row position-relative">
              <div className="col-lg-8 p-0 ">
                {/* catalogue images */}
                {bikeHighlightsData.h1 && (
                  <h1
                    className="text-center mt-3 mb-lg-0"
                    style={{
                      fontFamily: "Metropolis-medium",
                      fontSize: "1em",
                    }}
                  >
                    {bikeHighlightsData.h1}
                  </h1>
                )}
                <div className="d-flex position-relative align-items-center bike-product-images justify-content-center">
                  <div
                    className="position-absolute slider-count"
                    style={{
                      fontFamily: "Metropolis-bold",
                    }}
                  >
                    <h6 className="">
                      ({imageIndex}/
                      {bikeInfo.name === "T-Rex Pro" ||
                      bikeInfo.name === "Lil E"
                        ? bikeInfo.variants[variant]?.models.find(
                            (m) => m.modelIndex === model
                          )?.productImages.length
                        : bikeInfo.productImages[
                            bikeInfo.variants.length
                              ? bikeInfo.variants[variant].models[model]
                              : model
                          ].length}
                      )
                    </h6>
                  </div>
                  {bikeInfo.urlName === name && loadImageComponent && (
                    <Swiper
                      navigation={{ clickable: true }}
                      ref={sliderRef}
                      spaceBetween={15}
                      slidesPerView={1}
                      loop={true}
                      breakpoints={{
                        1024: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                      }}
                      onSlideChange={(swiper) => {
                        const newIndex = swiper.activeIndex % images.length;
                        if (newIndex === 0) {
                          setImageIndex(images.length);
                        } else {
                          setImageIndex(newIndex);
                        }
                      }}
                      onSwiper={(swiper) => {
                        const newIndex = swiper.activeIndex % images.length;
                        setImageIndex(newIndex);
                      }}
                    >
                      {images.map((item, index) => {
                        return (
                          <SwiperSlide className="p-1 w-100" key={index}>
                            <img
                              src={item}
                              alt={`Slide ${index}`}
                              className="img-fluid d-block mx-auto"
                              onLoad={handleImageLoad}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  )}
                </div>
                {/* ...........mobile details............ */}
                <div className="d-lg-none">
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h1
                          className="mb-1"
                          style={{
                            fontFamily: "Metropolis-bold",
                            fontSize: "1.7em",
                          }}
                        >
                          {bikeInfo.name === "X1 Limited Edition" ||
                          bikeInfo.name === "Legend 07 MSD Edition" ? (
                            <>
                              {bikeInfo.name === "X1 Limited Edition" ? (
                                <>
                                  X1{" "}
                                  <span
                                    style={{
                                      fontSize: "0.5em",
                                      fontFamily: "Metropolis-medium",
                                    }}
                                  >
                                    Limited Edition
                                  </span>
                                </>
                              ) : (
                                <>
                                  Legend 07{" "}
                                  <span
                                    style={{
                                      fontSize: "0.5em",
                                      fontFamily: "Metropolis-medium",
                                    }}
                                  >
                                    MSD Edition
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            bikeInfo.name
                          )}
                        </h1>
                        <div className="d-flex align-content-center">
                          <Icon
                            icon="ic:round-star-rate"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <Icon
                            icon="ic:round-star-rate"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <Icon
                            icon="ic:round-star-rate"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <Icon
                            icon="ic:round-star-rate"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <Icon
                            icon="ic:round-star-half"
                            style={{ color: "#ff9000" }}
                            width={"15"}
                          />
                          <span
                            className="ms-2"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {bikeHighlightsData.reviews.product[1]} reviews
                          </span>
                        </div>
                      </div>
                      <div>
                        <h4
                          className="text-end fw-bold"
                          style={{
                            fontFamily: "Metropolis-medium",
                          }}
                        >
                          {" "}
                          {bikeInfo.name === "T-Rex Pro" ||
                          bikeInfo.name === "Lil E"
                            ? currencyFormat(
                                bikeInfo.variants[variant]?.models.find(
                                  (m) => m.modelIndex === model
                                )?.price
                              )
                            : bikeInfo.models[
                                bikeInfo.variants.length
                                  ? bikeInfo.variants[variant].models[model]
                                  : model
                              ].price
                            ? currencyFormat(
                                bikeInfo.models[
                                  bikeInfo.variants.length
                                    ? bikeInfo.variants[variant].models[model]
                                    : model
                                ].price
                              )
                            : currencyFormat(bikeInfo.accPrice)}
                        </h4>
                        <h2
                          className="text-end "
                          style={{
                            textDecoration: "line-through",
                            fontFamily: "Metropolis-medium",
                          }}
                        >
                          {bikeInfo.name === "T-Rex Pro" ||
                          bikeInfo.name === "Lil E"
                            ? currencyFormat(
                                bikeInfo.variants[variant]?.models.find(
                                  (m) => m.modelIndex === model
                                )?.slashPrice
                              )
                            : bikeInfo.models[
                                bikeInfo.variants.length
                                  ? bikeInfo.variants[variant].models[model]
                                  : model
                              ].slashPrice
                            ? currencyFormat(
                                bikeInfo.models[
                                  bikeInfo.variants.length
                                    ? bikeInfo.variants[variant].models[model]
                                    : model
                                ].slashPrice
                              )
                            : currencyFormat(bikeInfo.slashPrice)}
                        </h2>
                      </div>
                    </div>
                    {bikeInfo.name === "Neo" && (
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p
                            className=""
                            style={{
                              fontFamily: "Metropolis-black",
                              color: "#004dac",
                            }}
                          >
                            Limited Stock: {bikeInfo.inStock}
                          </p>
                        </div>
                      </div>
                    )}
                    <hr className="mt-2" />
                    {bikeInfo.variants.length ? (
                      <>
                        <div className="mt-3 mb-4">
                          <p
                            style={{ fontFamily: "Metropolis-bold" }}
                            className="mb-1"
                          >
                            Variants
                          </p>
                          <div
                            className="d-flex flex-wrap"
                            style={{ gap: "1em" }}
                          >
                            {bikeInfo.variants.map((vari, i) => (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setVariant(i);
                                  setModel(0);
                                  sliderRef.current.swiper.slideTo(1);
                                }}
                                className={`border  d-flex align-items-center justify-content-center p-2 px-3 border-dark text-center rounded-pill ${
                                  variant === i && "active-color"
                                }`}
                              >
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Metropolis-bold",
                                  }}
                                >
                                  {vari.variantName}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <hr className="mt-2" />
                      </>
                    ) : (
                      ""
                    )}
                    {bikeInfo.name === "T-Rex Pro" ||
                    bikeInfo.name === "Lil E" ? (
                      <div className="mt-3 mb-4">
                        <p
                          style={{ fontFamily: "Metropolis-bold" }}
                          className="mb-1"
                        >
                          Colours
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "1em" }}
                        >
                          {bikeInfo.variants[variant].models.map((color, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModel(i);
                                sliderRef.current.swiper.slideTo(1);
                              }}
                              className={`border  d-flex align-items-center p-2 px-3 border-dark rounded-pill ${
                                model === color.modelIndex && "active-color"
                              }`}
                            >
                              <div
                                className="me-2 rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  background: `${
                                    bikeInfo.models[color.modelIndex].colorCode
                                  }`,
                                }}
                              ></div>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {bikeInfo.models[color.modelIndex].colorName}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : bikeInfo.variants.length ? (
                      <div className="mt-3 mb-4">
                        <p
                          style={{ fontFamily: "Metropolis-bold" }}
                          className="mb-1"
                        >
                          Colours
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "1em" }}
                        >
                          {bikeInfo.variants[variant].models.map((color, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModel(i);
                                sliderRef.current.swiper.slideTo(1);
                              }}
                              className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                                model === i && "active-color"
                              }`}
                            >
                              <div
                                className="me-2 rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  background: `${bikeInfo.models[color].colorCode}`,
                                }}
                              ></div>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {bikeInfo.models[color].colorName}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="mt-3 mb-4">
                        <p
                          style={{ fontFamily: "Metropolis-bold" }}
                          className="mb-1"
                        >
                          Colours
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "1em" }}
                        >
                          {bikeInfo.models.map((color, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModel(i);
                                sliderRef.current.swiper.slideTo(1);
                              }}
                              className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                                model === i && "active-color"
                              }`}
                            >
                              <div
                                className="me-2 rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  background: `${color.colorCode}`,
                                }}
                              ></div>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {color.colorName}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <hr className="mt-2" />
                    {/* {bikeInfo.name === "T-Rex Air" ? (
                      <div className="mt-3">
                        <Link
                          to={"/find-e-bicycle-store"}
                          className="btn w-100 p-lg-2 pt-3 pb-3 text-light em-btn rounded-5 disabled"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #004DAC",
                            fontFamily: "Metropolis-bold",
                          }}
                        >
                          OUT OF STOCK
                        </Link>
                        <div className="d-flex" style={{ gap: "1em" }}>
                          <div className="mt-3">
                            <p
                              className="text-dark fw-bold text-center mb-2"
                              style={{ color: "#ed2939" }}
                            >
                              Lucky customers to win a Journeyman backpack worth
                              Rs. 5000/-
                            </p>
                            <p
                              className="text-dark fw-bold text-center mb-0"
                              style={{ color: "#ed2939" }}
                            >
                              Delivery begins 30th April onwards
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : ( */}
                    <div className="mt-3">
                      {bikeInfo.name !== "Neo" && (
                        <Link
                          to={"/find-e-bicycle-store"}
                          className="btn w-100 p-lg-2 pt-3 pb-3  text-em rounded-5"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #004DAC",
                            fontFamily: "Metropolis-bold",
                          }}
                        >
                          BUY NOW AT YOUR NEAREST DEALERSHIP
                        </Link>
                      )}
                      <div className="d-flex mt-3" style={{ gap: "1em" }}>
                        <div className="w-100">
                          <a
                            href={bikeHighlightsData.links.amazon}
                            target="_blank"
                            className="btn d-flex justify-content-center align-items-center w-100 p-2 text-light rounded-5"
                            style={{
                              border: "1px solid #004DAC",
                              fontSize: "14px",
                              gap: "0.7em",
                              background: "#004dac",
                              fontFamily: "Metropolis-bold",
                            }}
                            rel="noreferrer"
                          >
                            BUY ON{" "}
                            <img
                              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/bi_amazon.png"
                              className="img-fluid "
                              style={{ width: "19px" }}
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="w-100">
                          <a
                            href={bikeHighlightsData.links.flipkart}
                            target="_blank"
                            className="btn d-flex justify-content-center align-items-center w-100 p-2 text-light rounded-5"
                            style={{
                              border: "1px solid #004DAC",
                              fontSize: "14px",
                              gap: "0.7em",
                              background: "#004dac",
                              fontFamily: "Metropolis-bold",
                            }}
                            rel="noreferrer"
                          >
                            BUY ON
                            <img
                              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/simple-icons_flipkart.png"
                              className="img-fluid "
                              style={{ width: "17px" }}
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                  <div className="line-break"></div>
                  <div className="p-3">
                    <div className="row pt-1 pb-1  pb-0 justify-content-around">
                      <div className="col-4 text-center p-0">
                        <Icon
                          icon="octicon:arrow-both-16"
                          width={25}
                          style={{ color: "#004dac" }}
                        />
                        <h6
                          className="fw-bold mb-0 mt-1 text-center"
                          style={{ fontFamily: "Metropolis-bold" }}
                        >
                          {bikeInfo.variants.length
                            ? bikeInfo.variants[variant].variantName === "5.2AH"
                              ? bikeHighlightsData.bikeSpecs.range
                              : bikeInfo.variants[variant].variantName ===
                                "7.8AH"
                              ? "Up to 20"
                              : bikeHighlightsData.bikeSpecs.range
                            : bikeHighlightsData.bikeSpecs.range}
                        </h6>
                        <p style={{ fontSize: "14px" }} className="m-0">
                          KM RANGE
                        </p>
                      </div>
                      <div className="col-4 text-center p-0">
                        <Icon
                          icon="ic:round-speed"
                          width={25}
                          style={{ color: "#004dac" }}
                        />

                        <h6
                          className="fw-bold mt-1 mb-0 text-center"
                          style={{ fontFamily: "Metropolis-bold" }}
                        >
                          {bikeHighlightsData.bikeSpecs.speed}
                        </h6>
                        <p style={{ fontSize: "14px" }} className="m-0">
                          KM/HR SPEED
                        </p>
                      </div>
                      <div className="col-4 text-center p-0">
                        <Icon
                          icon="fluent:battery-9-24-regular"
                          width={25}
                          style={{ color: "#004dac" }}
                        />

                        <h6
                          className="fw-bold mt-1 mb-0 text-center"
                          style={{ fontFamily: "Metropolis-bold" }}
                        >
                          {bikeInfo.variants.length
                            ? bikeInfo.variants[variant].variantName === "5.2AH"
                              ? bikeHighlightsData.bikeSpecs.battery
                              : bikeInfo.variants[variant].variantName ===
                                "7.8AH"
                              ? "7.8"
                              : bikeHighlightsData.bikeSpecs.battery
                            : bikeHighlightsData.bikeSpecs.battery}
                        </h6>
                        <p className="m-0" style={{ fontSize: "14px" }}>
                          AH BATTERY
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line-break"></div>
                {/* infographic creative */}
                {bikeHighlightsData.bikeGif ? (
                  <>
                    <div className="product-gif position-relative">
                      {bikeInfo.name !== "T-Rex Pro" &&
                        bikeInfo.name !== "ST-X" && (
                          <div
                            className="position-absolute"
                            style={{
                              width: "100%",
                              height: "100%",
                              zIndex: "1",
                              background: "rgba(0,0,0,0.5)",
                            }}
                          ></div>
                        )}
                      {/* <img
                    src={bikeHighlightsData.bikeGif.url}
                    alt="Banner1"
                    className="product-video  img-fluid"
                  /> */}

                      <video
                        playsInline
                        loop
                        className=" ratio d-block ratio-16x9"
                        autoPlay={true}
                        disableRemotePlayback
                        preload="auto"
                        webkit-playsinline
                        style={{
                          width: "100%",

                          objectFit: "cover",
                        }}
                        muted={true}
                      >
                        <source
                          media="(max-width:650px)"
                          src={bikeHighlightsData.bikeGif.url}
                          type="video/mp4"
                        />
                        <source
                          media="(min-width:651px)"
                          src={bikeHighlightsData.bikeGif.url}
                          type="video/ogg"
                        />
                      </video>
                      {bikeInfo.name !== "T-Rex Pro" &&
                      bikeInfo.name !== "ST-X" &&
                      bikeInfo.name !== "Lil E" ? (
                        <div>
                          <div className="product-gif-heading w-100">
                            <h3 className="text-light fw-bold">
                              {bikeHighlightsData.bikeGif.tagline}
                            </h3>
                          </div>
                          <div className="product-gif-specs">
                            <div class="spec-box">
                              <h4 className="mb-1 mt-1 text-center text-light fw-bold">
                                {bikeHighlightsData.bikeSpecs.battery}
                              </h4>
                              <p className="m-0 text-center">AH Battery</p>
                            </div>
                            <div class="spec-divider"></div>
                            <div class="spec-box">
                              <h4 className="mb-1 mt-1 text-center text-light fw-bold">
                                {bikeHighlightsData.bikeSpecs.range}
                              </h4>
                              <p className="m-0 text-center">KM Range</p>
                            </div>
                            <div class="spec-divider"></div>
                            <div class="spec-box ">
                              <h4 className="mb-1 mt-1 text-center text-light fw-bold">
                                {bikeHighlightsData.name === "t-rex+"
                                  ? "Cluster i8"
                                  : bikeHighlightsData.bikeSpecs.speed}
                              </h4>
                              <p className="m-0 text-center">
                                {bikeHighlightsData.name === "t-rex+"
                                  ? "Stem Integrated Display"
                                  : "KM/HR Top Speed"}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="line-break"></div>
                  </>
                ) : (
                  <></>
                )}

                {/* USP Section */}
                <div className="structure-design ps-lg-5 pe-lg-5 ">
                  <h1
                    className="my-4 structure-main-heading text-em heading-homepage"
                    style={{ fontFamily: "Metropolis-black" }}
                  >
                    {bikeHighlightsData.structure.structureHeadline}
                  </h1>{" "}
                  <p>{bikeHighlightsData.structure.structureDescription}</p>
                  <div className="d-none mt-3 d-lg-block tool-tip">
                    <Tooltip bike={name} style={{ width: "1rem" }} />
                  </div>
                  {bikeInfo.name !== "Lil E" && (
                    <div className=" d-lg-none">
                      <TooltipMobile bike={name} />
                    </div>
                  )}
                </div>
                <div className="line-break"></div>
                {bikeInfo.name !== "Lil E" && (
                  <>
                    <div className="p-3 mt-3 ps-lg-5 pe-lg-5 ">
                      <h1
                        className="mb-4 structure-main-heading text-em overflow"
                        style={{ fontFamily: "Passion One" }}
                      >
                        Compare The Best
                      </h1>{" "}
                      <div className="row">
                        <div className="col-3"></div>
                        <div className="col-4 p-0">
                          <img
                            className="img-fluid"
                            src={
                              bike1 === bikeInfo.name
                                ? bikeInfo.cartImages[model]
                                : bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.image
                            }
                          ></img>
                          <div className="product-select">
                            <select
                              onChange={(e) => {
                                setBike1(e.target.value);
                              }}
                              className="form-select w-100 text-light text-center mobile-bike-select"
                              required
                              style={{
                                padding: "0.5em",
                                backgroundColor: "#004dac",
                              }}
                              id="exampleFormControlInput1"
                            >
                              {bikeSpecs.map((item, index) => (
                                <option
                                  selected={item.name === bike1}
                                  key={index}
                                  value={item.name}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-1 d-lg-block d-none"></div>

                        <div className="col-4 p-0 ms-3 ms-lg-0">
                          <img
                            className="img-fluid"
                            src={
                              bikeSpecs.find((bike) => bike.name === bike2)
                                ?.image
                            }
                          ></img>
                          <div className="product-select">
                            <select
                              onChange={(e) => {
                                setBike2(e.target.value);
                              }}
                              className="form-select w-100 text-light text-center mobile-bike-select"
                              required
                              style={{
                                padding: "0.5em",
                                backgroundColor: "#004dac",
                              }}
                              id="exampleFormControlInput1"
                            >
                              {bikeSpecs.map((item, index) => (
                                <option
                                  key={index}
                                  value={item.name}
                                  selected={item.name === bike2}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-3 pe-0">
                          <table>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Price
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Frame
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Gears
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Range
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Tyre
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Suspension
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Display
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Battery
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Rider Weight Capacity
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Rider Height
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #ffffff",
                                }}
                              >
                                Frame Warranty
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div
                          className="col-4"
                          style={{
                            border: "1px solid #C5C5C5",
                            borderRadius: "5px",
                            backgroundColor: "#f4f4f4",
                          }}
                        >
                          <table
                            className="text-center"
                            style={{ width: "-webkit-fill-available" }}
                          >
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.price
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.frame
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.gears
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.range
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {bikeInfo.name === "T-Rex Pro" ||
                                bikeInfo.name === "Lil E"
                                  ? bikeInfo.variants[variant].variantName ===
                                    '27.5"'
                                    ? '27.5" x 2.25 Nylon Tyres'
                                    : '29" x 2.25 Nylon Tyres'
                                  : bikeSpecs.find(
                                      (bike) => bike.name === bike1
                                    )?.tyre}
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.suspension
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.display
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.battery
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.weight
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {bikeInfo.name === "T-Rex Pro" ||
                                bikeInfo.name === "Lil E"
                                  ? bikeInfo.variants[variant].variantName ===
                                    '27.5"'
                                    ? "5.5FT TO 5.9FT"
                                    : "5.8FT TO 5.11FT"
                                  : bikeSpecs.find(
                                      (bike) => bike.name === bike1
                                    )?.height}
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike1)
                                    ?.warranty
                                }
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-1 d-lg-block d-none"></div>
                        <div
                          className="col-4 ms-3 ms-lg-0"
                          style={{
                            border: "1px solid #C5C5C5",
                            borderRadius: "5px",
                            backgroundColor: "#f4f4f4",
                          }}
                        >
                          <table
                            className="text-center"
                            style={{ width: "-webkit-fill-available" }}
                          >
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.price
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.frame
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.gears
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.range
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.tyre
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.suspension
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.display
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.battery
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.weight
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                  borderBottom: "1px solid #C5C5C5",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.height
                                }
                              </td>
                            </tr>
                            <tr className="comparison-feature-block">
                              <td
                                className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                                style={{
                                  color: "#004dac",
                                  fontFamily: "metropolis-bold",
                                }}
                              >
                                {
                                  bikeSpecs.find((bike) => bike.name === bike2)
                                    ?.warranty
                                }
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="line-break"></div>
                  </>
                )}
                <div className="lifeStyle-breaker">
                  <img
                    className="w-100"
                    src={
                      bikeInfo.models[model].colorName === "Bleed Blue"
                        ? bikeHighlightsData.lifestyleImages[2]
                        : bikeHighlightsData.lifestyleImages[0]
                    }
                    alt=""
                  />
                </div>
                <div className="line-break"></div>
                <div className="specs-list p-3 ps-lg-5 pe-lg-5">
                  <div className="mb-3">
                    <div
                      className="d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample1"
                      aria-expanded={isDimensionOpen ? "true" : "false"}
                      aria-controls="collapseExample"
                      onClick={toggleDimensionCollapse}
                    >
                      <h1
                        className="structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        Dimensions
                      </h1>
                      <span className="iconify">
                        {isDimensionOpen ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </span>
                    </div>
                    <div
                      className="collapse show mt-lg-3 ps-lg-4"
                      id="collapseExample1"
                    >
                      <div className="row">
                        <div className="col-md-6 d-lg-none">
                          <img
                            src={
                              bikeInfo.name === "T-Rex Pro" ||
                              bikeInfo.name === "Lil E"
                                ? bikeHighlightsData.dimensionImage[
                                    bikeInfo.variants[variant]?.models.find(
                                      (m) => m.modelIndex === model
                                    )?.modelIndex
                                  ]
                                : bikeInfo.variants.length
                                ? bikeHighlightsData.dimensionImage[
                                    bikeInfo.variants[variant].models[model]
                                  ]
                                : bikeHighlightsData.dimensionImage[model]
                            }
                            className="img-fluid"
                            alt="Bike Dimension"
                            style={{ translate: "-3% 0%" }}
                          />
                        </div>
                        <div className="col-md-6">
                          {bikeInfo.specs.map(
                            (item, i) =>
                              i <= 5 && (
                                <div key={i} className="row ">
                                  <div className="col-md-6 col-5">
                                    <p className="m-0 specs-feature">
                                      {item.name}
                                    </p>
                                  </div>
                                  <div className="col-md-6 col-7">
                                    <p className="m-0 specs-feature">
                                      {item.value}
                                    </p>
                                  </div>
                                  <hr
                                    className="p-0"
                                    style={{ marginTop: "8px" }}
                                  />
                                </div>
                              )
                          )}
                          {bikeInfo.specs.map(
                            (item, i) =>
                              i > 5 && (
                                <div
                                  key={i}
                                  className="collapse"
                                  id="collapseEx"
                                >
                                  <div className="row">
                                    <div className="col-md-6 col-5 ">
                                      <p className="m-0 specs-feature">
                                        {item.name}
                                      </p>
                                    </div>
                                    <div className="col-md-6 col-7">
                                      <p className="m-0 specs-feature">
                                        {item.value}
                                      </p>
                                    </div>
                                    <hr
                                      className="p-0"
                                      style={{ marginTop: "8px" }}
                                    />
                                  </div>
                                </div>
                              )
                          )}
                          <button
                            className="spec-btn mt-4 mb-4 mx-auto d-block "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEx"
                            aria-expanded="false"
                            onClick={() => setCollapse(!Collapse)}
                            aria-controls="collapseExample"
                          >
                            {Collapse ? "Collapse" : " See More"}
                          </button>
                        </div>
                        <div className="col-md-6 dimension-image">
                          <img
                            src={
                              bikeInfo.name === "T-Rex Pro" ||
                              bikeInfo.name === "Lil E"
                                ? bikeHighlightsData.dimensionImage[
                                    bikeInfo.variants[variant]?.models.find(
                                      (m) => m.modelIndex === model
                                    )?.modelIndex
                                  ]
                                : bikeInfo.variants.length
                                ? bikeHighlightsData.dimensionImage[
                                    bikeInfo.variants[variant].models[model]
                                  ]
                                : bikeHighlightsData.dimensionImage[model]
                            }
                            className="img-fluid"
                            alt="Bike Dimension"
                            style={{ translate: "0% -10%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {!isDimensionOpen && (
                    <hr className="p-0" style={{ marginTop: "16px" }} />
                  )}
                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTechnicalDetails"
                      aria-expanded={isTechnicalDetailsOpen ? "true" : "false"}
                      aria-controls="collapseExample"
                      onClick={toggleTechnicalDetailsCollapse}
                    >
                      <h1
                        className="structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        Technical Details
                      </h1>
                      <span className="iconify">
                        {isTechnicalDetailsOpen ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </span>
                    </div>

                    <div
                      className="collapse mt-3"
                      id="collapseTechnicalDetails"
                    >
                      {bikeInfo.technicalSpecs.map((item, i) => (
                        <div key={i} className="row ps-lg-4 ps-2">
                          <div className="col-md-6 col-5">
                            <p className="m-0 specs-feature">{item.name}</p>
                          </div>
                          <div className="col-md-6 col-7">
                            {item.name === "MRP" ? (
                              <p className="m-0 specs-feature">
                                {currencyFormat(bikeInfo.slashPrice)}.00
                                (inclusive of all taxes)
                              </p>
                            ) : (
                              <p
                                className="m-0 specs-feature"
                                dangerouslySetInnerHTML={{ __html: item.value }}
                              />
                            )}
                          </div>
                          {i === bikeInfo.technicalSpecs.length - 1 ? (
                            <></>
                          ) : (
                            <hr className="p-0" style={{ marginTop: "8px" }} />
                          )}
                        </div>
                      ))}
                      <hr className="p-0" style={{ marginTop: "16px" }} />
                    </div>
                  </div>
                  {!isTechnicalDetailsOpen && (
                    <hr className="p-0" style={{ marginTop: "16px" }} />
                  )}
                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample4"
                      aria-expanded={isShippingOpen ? "true" : "false"}
                      aria-controls="collapseExample"
                      onClick={toggleShippingCollapse}
                    >
                      <h1
                        className="structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        Shipping Terms
                      </h1>
                      <span className="iconify">
                        {isShippingOpen ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </span>
                    </div>

                    <div className="collapse mt-3" id="collapseExample4">
                      <p className="ps-lg-4 ps-2">
                        <b>Shipping Terms:</b> Available shipping options will
                        be displayed during the checkout process. Any timeframe
                        provided by EMotorad for when the Product(s) may ship or
                        be delivered is a good faith estimate. While we do our
                        best to meet that timeframe, it is not a guarantee.
                        Actual delivery of your order can be impacted by many
                        events for which EMotorad cannot be held liable. If you
                        no longer have use for an item due to a late delivery,
                        please contact our Customer Service Department
                        immediately.
                      </p>
                      <p className="ps-lg-4 ps-2">
                        <b>Inspection on Receipt:</b> Customers are advised to
                        immediately inspect the packaging for any signs of
                        damage upon receiving the goods. If any obvious damage
                        is observed, customers have the option to reject the
                        delivery and should contact EMotorad promptly.
                      </p>
                      <p className="ps-lg-4 ps-2">
                        <b>Reporting Shipping Damage:</b> Claims for shipping
                        damage must be made within 24 hours of receiving the
                        product. EMotorad will not accept claims for shipping
                        damage reported later than this timeframe.
                      </p>
                      <p className="ps-lg-4 ps-2">
                        <b>Rejecting Damaged Goods:</b> If customers notice
                        obvious damage to the product packaging, they have the
                        right to reject the delivery. Customers should contact
                        EMotorad immediately to initiate the claims process.
                      </p>
                      <p className="ps-lg-4 ps-2">
                        <b>Warranty Exclusions:</b> Shipping damage caused by
                        neglect, misuse, abuse, or accidents is not covered
                        under the warranty. Normal wear and tear, incorrect
                        installation, alterations, and tampering damage are also
                        excluded from warranty coverage.
                      </p>
                      <p className="ps-lg-4 ps-2">
                        <b>Geographical Limitations:</b> The warranty is valid
                        only within the geographical boundaries of the Indian
                        subcontinent.
                      </p>
                      <p className="ps-lg-4 ps-2">
                        <b>Change of Warranty Policy:</b> EMotorad reserves the
                        right to change the warranty policy at its sole
                        discretion without any prior notice.
                      </p>
                      <p className="ps-lg-4 ps-2">
                        <b>Contact Information:</b> For offline and online
                        purchases, customers should reach out to the EMotorad
                        service team regarding any shipping damage claims or
                        warranty-related queries. Please note that these terms
                        are subject to change by EMotorad, and customers are
                        advised to refer to the latest terms and conditions
                        provided by the company.
                      </p>
                      <hr className="p-0" style={{ marginTop: "14px" }} />
                    </div>
                  </div>
                  {!isShippingOpen && (
                    <hr className="p-0" style={{ marginTop: "16px" }} />
                  )}
                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample3"
                      aria-expanded={isDescOpen ? "true" : "false"}
                      aria-controls="collapseExample"
                      onClick={toggleDescCollapse}
                    >
                      <h1
                        className="structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        Still Have Doubts?
                      </h1>
                      <span className="iconify">
                        {isDescOpen ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </span>
                    </div>

                    <div className="collapse p-2" id="collapseExample3">
                      <div className="p-lg-3 p-2">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSubExample2"
                          aria-expanded={isDoubtsTwo ? "true" : "false"}
                          aria-controls="collapseExample"
                          onClick={toggleDoubtsTwoCollapse}
                        >
                          <p
                            className="fw-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            Why are we priced higher than ordinary cycles?
                          </p>
                          <span className="iconify">
                            {isDoubtsTwo ? (
                              <i className="fa-solid fa-minus"></i>
                            ) : (
                              <i className="fa-solid fa-plus"></i>
                            )}
                          </span>
                        </div>
                        <div className="collapse mt-3" id="collapseSubExample2">
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/stillHaveDoubts/Artboard+1+(2).jpg"
                            className="img-fluid"
                          ></img>
                        </div>
                      </div>
                      <div className="p-lg-3 p-2">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSubExample3"
                          aria-expanded={isDoubtsThree ? "true" : "false"}
                          aria-controls="collapseExample"
                          onClick={toggleDoubtsThreeCollapse}
                        >
                          <p
                            className="fw-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            How are we different from other electric cycle
                            brands?
                          </p>
                          <span className="iconify">
                            {isDoubtsThree ? (
                              <i className="fa-solid fa-minus"></i>
                            ) : (
                              <i className="fa-solid fa-plus"></i>
                            )}
                          </span>
                        </div>
                        <div className="collapse mt-3" id="collapseSubExample3">
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/stillHaveDoubts/Artboard+2+(2).jpg"
                            className="img-fluid"
                          ></img>
                        </div>
                      </div>
                      <div className="p-lg-3 p-2">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSubExample4"
                          aria-expanded={isDoubtsFour ? "true" : "false"}
                          aria-controls="collapseExample"
                          onClick={toggleDoubtsFourCollapse}
                        >
                          <p
                            className="fw-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            Why should you trust us?
                          </p>
                          <span className="iconify">
                            {isDoubtsFour ? (
                              <i className="fa-solid fa-minus"></i>
                            ) : (
                              <i className="fa-solid fa-plus"></i>
                            )}
                          </span>
                        </div>
                        <div className="collapse mt-3" id="collapseSubExample4">
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/stillHaveDoubts/Artboard+3+(1).jpg"
                            className="img-fluid"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="p-0" style={{ marginTop: "16px" }} />

                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample5"
                      aria-expanded={isFaqOpen ? "true" : "false"}
                      aria-controls="collapseExample"
                      onClick={toggleFaqCollapse}
                    >
                      <h1
                        className="structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        FAQs
                      </h1>
                      <span className="iconify">
                        {isFaqOpen ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </span>
                    </div>

                    <div className="collapse mt-3" id="collapseExample5">
                      {bikeHighlightsData.faq
                        ? bikeInfo.name === "Lil E"
                          ? bikeHighlightsData.faq[variant].map(
                              (faq, index) => (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    id={`question${index + 1}`}
                                    name="q"
                                    className="questions"
                                  ></input>
                                  <label
                                    htmlFor={`question${index + 1}`}
                                    className="question"
                                  >
                                    {index + 1}. {faq.question}
                                  </label>
                                  <div
                                    className="answers"
                                    dangerouslySetInnerHTML={{
                                      __html: faq.answer,
                                    }}
                                  ></div>
                                  <hr
                                    className="p-0"
                                    style={{ marginTop: "16px" }}
                                  />
                                </div>
                              )
                            )
                          : bikeHighlightsData.faq.map((faq, index) => (
                              <div key={index}>
                                <input
                                  type="checkbox"
                                  id={`question${index + 1}`}
                                  name="q"
                                  className="questions"
                                ></input>
                                <label
                                  htmlFor={`question${index + 1}`}
                                  className="question"
                                >
                                  {index + 1}. {faq.question}
                                </label>
                                <div
                                  className="answers"
                                  dangerouslySetInnerHTML={{
                                    __html: faq.answer,
                                  }}
                                ></div>
                                <hr
                                  className="p-0"
                                  style={{ marginTop: "16px" }}
                                />
                              </div>
                            ))
                        : FAQs.map((faq, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                id={`question${index + 1}`}
                                name="q"
                                className="questions"
                              ></input>
                              <label
                                htmlFor={`question${index + 1}`}
                                className="question"
                              >
                                {index + 1}. {faq.question}
                              </label>
                              <div
                                className="answers"
                                dangerouslySetInnerHTML={{ __html: faq.answer }}
                              ></div>
                              <hr
                                className="p-0"
                                style={{ marginTop: "16px" }}
                              />
                            </div>
                          ))}
                    </div>
                  </div>
                  {!isFaqOpen && (
                    <hr className="p-0" style={{ marginTop: "16px" }} />
                  )}
                </div>
                <div className="line-break"></div>
                <div className="lifeStyle-breaker">
                  <img
                    className="w-100"
                    src={
                      bikeInfo.models[model].colorName === "Bleed Blue"
                        ? bikeHighlightsData.lifestyleImages[3]
                        : bikeHighlightsData.lifestyleImages[1]
                    }
                    alt=""
                  />
                </div>
                <div className="line-break"></div>
                <div className="reviews-div  p-3 ps-lg-4">
                  <div className=" mb-lg-4 reviews-sub-div">
                    <div className="">
                      <h1
                        className="mb-4 structure-main-heading text-em heading-homepage"
                        style={{ fontFamily: "Metropolis-black" }}
                      >
                        Some Rider Love!
                      </h1>
                      <p className="product-text mb-1 mb-lg-3">
                        {bikeHighlightsData.reviews.productText}
                      </p>
                      <div
                        className="mb-2 mb-lg-5"
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontWeight: "600",
                            fontSize: "1.3em",
                          }}
                        >
                          <span style={{ fontSize: "2em" }}>
                            {bikeHighlightsData.reviews.product[0]}
                          </span>
                          /5
                        </p>
                        <Icon icon="emojione:star" />
                        <Icon icon="emojione:star" />
                        <Icon icon="emojione:star" />
                        <Icon icon="emojione:star" />
                        <Icon icon="emojione:star" />
                        <p className="reviews-text">1965 reviews</p>
                      </div>
                      <div className="row reviews-logo-sec">
                        <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                          <img
                            className="reviews-logo"
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/amazon-logo_2.png"
                            alt="Amazon Logo"
                          />
                          <div className="d-flex flex-column align-items-center reviews-logo-div">
                            <div className="d-flex">
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                            </div>
                            <p
                              style={{ margin: 0, padding: 0 }}
                              className="review-logo-text"
                            >
                              1640 reviews
                            </p>
                          </div>
                        </div>
                        <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                          <img
                            className="reviews-logo"
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/flipkart-logo1.png"
                            alt="Amazon Logo"
                          />
                          <div className="d-flex flex-column align-items-center reviews-logo-div">
                            <div className="d-flex">
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                            </div>
                            <p
                              style={{ margin: 0, padding: 0 }}
                              className="review-logo-text"
                            >
                              193 reviews
                            </p>
                          </div>
                        </div>
                        <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                          <img
                            className="reviews-logo"
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Google__G__logo.png"
                            alt="Amazon Logo"
                          />
                          <div className="d-flex flex-column align-items-center reviews-logo-div">
                            <div className="d-flex">
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                              <Icon
                                icon="emojione:star"
                                className="star-logo"
                              />
                            </div>
                            <p
                              style={{ margin: 0, padding: 0 }}
                              className="review-logo-text"
                            >
                              132 reviews
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-lg-none">
                    <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                      <img
                        className="reviews-logo"
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/amazon-logo_2.png"
                        alt="Amazon Logo"
                      />
                      <div className="d-flex flex-column align-items-center reviews-logo-div">
                        <div className="d-flex">
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                        </div>
                        <p
                          style={{ margin: 0, padding: 0 }}
                          className="review-logo-text"
                        >
                          1640 reviews
                        </p>
                      </div>
                    </div>
                    <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                      <img
                        className="reviews-logo"
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/flipkart-logo1.png"
                        alt="Amazon Logo"
                      />
                      <div className="d-flex flex-column align-items-center reviews-logo-div">
                        <div className="d-flex">
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                        </div>
                        <p
                          style={{ margin: 0, padding: 0 }}
                          className="review-logo-text"
                        >
                          193 reviews
                        </p>
                      </div>
                    </div>
                    <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                      <img
                        className="reviews-logo"
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Google__G__logo.png"
                        alt="Amazon Logo"
                      />
                      <div className="d-flex flex-column align-items-center reviews-logo-div">
                        <div className="d-flex">
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                          <Icon icon="emojione:star" className="star-logo" />
                        </div>
                        <p
                          style={{ margin: 0, padding: 0 }}
                          className="review-logo-text"
                        >
                          132 reviews
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-3">
                    <div className="">
                      <Splide
                        className="p-0"
                        options={{
                          arrows: false,
                          drag: false,
                          type: "loop",
                          pagination: false,
                          autoplay: false,
                          perMove: 1,
                          gap: "1em",
                          pauseOnHover: false,
                          resetProgress: false,
                          interval: 5000,
                          perPage: 3,
                          speed: 1000,
                          breakpoints: {
                            640: {
                              perPage: 1,
                              pagination: true,
                              arrows: false,
                              drag: true,
                              autoplay: true,
                            },
                          },
                        }}
                      >
                        {bikeHighlightsData.reviews.testimonials &&
                          bikeHighlightsData.reviews.testimonials.map(
                            (item) => (
                              <SplideSlide>
                                <div class="card h-100">
                                  <img
                                    src={item.image}
                                    class="card-img-top"
                                    style={{ height: "50%" }}
                                    alt="..."
                                  />
                                  <div class="card-body">
                                    <div className="row">
                                      <div
                                        className="col-6"
                                        style={{
                                          fontFamily: "Metropolis-bold",
                                        }}
                                      >
                                        {item.name}
                                      </div>
                                      <div className="col-6">
                                        <div className="d-flex align-content-center">
                                          <Icon
                                            icon="ic:round-star-rate"
                                            style={{ color: "#ff9000" }}
                                            width={"20"}
                                          />
                                          <Icon
                                            icon="ic:round-star-rate"
                                            style={{ color: "#ff9000" }}
                                            width={"20"}
                                          />
                                          <Icon
                                            icon="ic:round-star-rate"
                                            style={{ color: "#ff9000" }}
                                            width={"20"}
                                          />
                                          <Icon
                                            icon="ic:round-star-rate"
                                            style={{ color: "#ff9000" }}
                                            width={"20"}
                                          />
                                          <Icon
                                            icon="ic:round-star-half"
                                            style={{ color: "#ff9000" }}
                                            width={"20"}
                                          />
                                          <span
                                            className="ms-2"
                                            style={{
                                              fontSize: "16px",
                                              fontFamily: "Metropolis-bold",
                                            }}
                                          >
                                            {item.rating}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "0.9em",
                                      }}
                                      class="card-text mt-3"
                                    >
                                      {item.content}
                                    </p>
                                  </div>
                                </div>
                              </SplideSlide>
                            )
                          )}
                      </Splide>
                    </div>
                  </div> */}
                  {/* <div
                    className="row d-none d-lg-block"
                    style={{ gap: "0em", margin: "auto" }}
                  >
                    <div className="col-6 ">
                      <div
                        className="mt-3 rounded-5 p-2"
                        style={{ backgroundColor: "#EAEAEA" }}
                      >
                        <p className="fw-bold" style={{ margin: "10px" }}>
                          {bikeHighlightsData.reviews.testimonials
                            ? bikeHighlightsData.reviews.testimonials[0].heading
                            : "Emotorad X1 is the best electric cycle under 25K."}
                        </p>
                        <p style={{ margin: "10px" }}>
                          {bikeHighlightsData.reviews.testimonials
                            ? bikeHighlightsData.reviews.testimonials[0].content
                            : "The Emotorad X1 electric cycle has surpassed all my expectations. Its sleek design and powerful performance have truly impressed me. The build quality is exceptional, providing a sturdy and reliable feel. The battery life is impressive, allowing for extended rides without worry. The pedal-assist feature makes uphill climbs a breeze. The electric motor delivers a smooth and responsive ride, and the overall handling is superb. Whether you're a seasoned cyclist or new to electric bikes, the Emotorad X1 offers a fantastic riding experience. I highly recommend it for its high-quality build, performance, and affordability."}
                        </p>
                        <p className="fw-bold" style={{ margin: "10px" }}>
                          {bikeHighlightsData.reviews.testimonials
                            ? bikeHighlightsData.reviews.testimonials[0].name
                            : "- Sheena Anand"}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className="mt-3 rounded-5 p-2"
                        style={{ backgroundColor: "#EAEAEA" }}
                      >
                        <p className="fw-bold" style={{ margin: "10px" }}>
                          {bikeHighlightsData.reviews.testimonials
                            ? bikeHighlightsData.reviews.testimonials[1].heading
                            : "Emotorad X1 is the best electric cycle under 25K."}
                        </p>
                        <p style={{ margin: "10px" }}>
                          {bikeHighlightsData.reviews.testimonials
                            ? bikeHighlightsData.reviews.testimonials[1].content
                            : "The Emotorad X1 electric cycle has surpassed all my expectations. Its sleek design and powerful performance have truly impressed me. The build quality is exceptional, providing a sturdy and reliable feel. The battery life is impressive, allowing for extended rides without worry. The pedal-assist feature makes uphill climbs a breeze. The electric motor delivers a smooth and responsive ride, and the overall handling is superb. Whether you're a seasoned cyclist or new to electric bikes, the Emotorad X1 offers a fantastic riding experience. I highly recommend it for its high-quality build, performance, and affordability."}
                        </p>
                        <p className="fw-bold" style={{ margin: "10px" }}>
                          {bikeHighlightsData.reviews.testimonials
                            ? bikeHighlightsData.reviews.testimonials[1].name
                            : "- Sheena Anand"}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="mt-3 d-flex align-items-center justify-content-center">
                    <button
                      type="submit"
                      className="mt-3 btn btn-lg read-button"
                    >
                      Read 550+ Reviews
                    </button>
                  </div> */}
                </div>
                <div className="line-break"></div>
              </div>
              <div
                className="background-em d-lg-none position-sticky p-2"
                style={{
                  bottom: "0%",
                  border: "0px",
                  width: "100%",
                  zIndex: "2",
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    gap: "1.5em",
                    bottom: "0%",
                    border: "0px",
                    width: "100%",
                    zIndex: "2",
                  }}
                >
                  <div className="" style={{ width: "100%" }}>
                    <Link
                      to={"/testride"}
                      style={{
                        color: "inherit",
                        backgroundColor: "#00ff7f",
                        fontFamily: "Metropolis-bold",
                        borderRadius: "25px",
                      }}
                      className="btn p-2 fw-bold w-100 btn-light d-block ms-auto text-em border-0"
                    >
                      BOOK TEST RIDE
                    </Link>
                  </div>
                  <div className=" col-6 ">
                    {bikeInfo.inStock ? (
                      bikeInfo.name === "T-Rex Pro" ||
                      bikeInfo.name === "Lil E" ? (
                        bikeInfo.variants[variant]?.models.find(
                          (m) => m.modelIndex === model
                        )?.inStock ? (
                          <button
                            className="btn btn-light w-100 text-em border-0 rounded-5 pt-3 pb-3 p-3 ps-5 pe-5"
                            style={{
                              padding: "1em 4em",
                              fontFamily: "Metropolis-bold",
                            }}
                            onClick={() =>
                              addCart(bikeInfo._id, model, 1, 1, variant)
                            }
                          >
                            BUY NOW
                          </button>
                        ) : (
                          <a
                            className="btn btn-light w-100 text-em disabled border-0 rounded-5 pt-3 pb-3 p-3 text-decoration-none"
                            style={{
                              fontFamily: "Metropolis-bold",
                            }}
                            href=""
                          >
                            OUT OF STOCK
                          </a>
                        )
                      ) : bikeInfo.models[
                          bikeInfo.variants.length
                            ? bikeInfo.variants[variant].models[model]
                            : model
                        ].inStock ? (
                        <button
                          className="btn p-2 fw-bold w-100 btn-light d-block ms-auto text-em border-0 "
                          style={{
                            fontFamily: "Metropolis-bold",
                            borderRadius: "25px",
                          }}
                          onClick={() =>
                            addCart(bikeInfo._id, model, 1, 1, variant)
                          }
                        >
                          BUY NOW
                        </button>
                      ) : (
                        <a
                          className="btn p-2 fw-bold disabled w-100 btn-light d-block ms-auto text-em border-0 text-deoration-none"
                          style={{
                            fontFamily: "Metropolis-bold",
                            borderRadius: "25px",
                          }}
                          href=""
                        >
                          OUT OF STOCK
                        </a>
                      )
                    ) : (
                      // bikeInfo.name === "T-Rex Air" ? (
                      //   <a
                      //     className="btn p-2 fw-bold w-100 btn-light d-block ms-auto text-em border-0 text-deoration-none"
                      //     style={{
                      //       fontFamily: "Metropolis-bold",
                      //       borderRadius: "25px",
                      //     }}
                      //     href="https://rzp.io/l/xV2C7BfKcK"
                      //   >
                      //     PRE-BOOK @ ₹ 999
                      //   </a>
                      // ) :
                      <a
                        className="btn p-2 fw-bold disabled w-100 btn-light d-block ms-auto text-em border-0 text-deoration-none"
                        style={{
                          fontFamily: "Metropolis-bold",
                          borderRadius: "25px",
                        }}
                        href=""
                      >
                        OUT OF STOCK
                      </a>
                    )}
                  </div>
                </div>
                {/* {!bikeInfo.inStock && (
                  <p className="text-light m-0 mt-2 text-center">
                    It will be dispatched soon
                  </p>
                )} */}
              </div>

              {/* .........................Fixed Desktop bike details................................ */}
              <div
                className="col-lg-4 d-none d-lg-block p-0 position-sticky"
                style={{ height: "85vh", overflowY: "auto", top: "14%" }}
              >
                <div className="p-4 pe-5" style={{ minHeight: "fit-content" }}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h1
                        className="mb-1"
                        style={{
                          fontFamily: "Metropolis-bold",
                          fontSize: "2em",
                        }}
                      >
                        {bikeInfo.name === "X1 Limited Edition" ||
                        bikeInfo.name === "Legend 07 MSD Edition" ? (
                          <>
                            {bikeInfo.name === "X1 Limited Edition" ? (
                              <>
                                X1{" "}
                                <span
                                  style={{
                                    fontSize: "0.5em",
                                    fontFamily: "Metropolis-medium",
                                  }}
                                >
                                  Limited Edition
                                </span>
                              </>
                            ) : (
                              <>
                                Legend 07{" "}
                                <p
                                  className="m-0 mt-1 mb-2"
                                  style={{
                                    fontSize: "0.5em",
                                    fontFamily: "Metropolis-medium",
                                  }}
                                >
                                  MSD Edition
                                </p>
                              </>
                            )}
                          </>
                        ) : (
                          bikeInfo.name
                        )}
                      </h1>
                      <div className="d-flex align-items-end">
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <Icon
                          icon="ic:round-star-half"
                          style={{ color: "#ff9000" }}
                          width={"20"}
                        />
                        <span
                          className="ms-2 mb-0"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {bikeHighlightsData.reviews.product[1]} Reviews
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4
                        className="text-end fw-bold"
                        style={{
                          fontFamily: "Metropolis-medium",
                        }}
                      >
                        {" "}
                        {bikeInfo.name === "T-Rex Pro" ||
                        bikeInfo.name === "Lil E"
                          ? currencyFormat(
                              bikeInfo.variants[variant]?.models.find(
                                (m) => m.modelIndex === model
                              )?.price
                            )
                          : bikeInfo.models[
                              bikeInfo.variants.length
                                ? bikeInfo.variants[variant].models[model]
                                : model
                            ].price
                          ? currencyFormat(
                              bikeInfo.models[
                                bikeInfo.variants.length
                                  ? bikeInfo.variants[variant].models[model]
                                  : model
                              ].price
                            )
                          : currencyFormat(bikeInfo.accPrice)}
                      </h4>
                      <h3
                        className="text-end "
                        style={{
                          textDecoration: "line-through",
                          fontFamily: "Metropolis-medium",
                        }}
                      >
                        {bikeInfo.name === "T-Rex Pro" ||
                        bikeInfo.name === "Lil E"
                          ? currencyFormat(
                              bikeInfo.variants[variant]?.models.find(
                                (m) => m.modelIndex === model
                              )?.slashPrice
                            )
                          : bikeInfo.models[
                              bikeInfo.variants.length
                                ? bikeInfo.variants[variant].models[model]
                                : model
                            ].slashPrice
                          ? currencyFormat(
                              bikeInfo.models[
                                bikeInfo.variants.length
                                  ? bikeInfo.variants[variant].models[model]
                                  : model
                              ].slashPrice
                            )
                          : currencyFormat(bikeInfo.slashPrice)}
                      </h3>
                    </div>
                  </div>
                  {bikeInfo.name === "Neo" && (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p
                          className=""
                          style={{
                            fontFamily: "Metropolis-black",
                            color: "#004dac",
                          }}
                        >
                          Limited Stock: {bikeInfo.inStock}
                        </p>
                      </div>
                    </div>
                  )}

                  <hr className="m-0" />
                  <div className="mt-3 pb-1">
                    <div className="d-flex mt-2 align-content-center">
                      <Icon icon="ph:seal-check-light" width={23} />{" "}
                      <p
                        style={{
                          fontSize: "15px",
                          fontFamily: "Metropolis-medium",
                        }}
                        className="ms-3"
                      >
                        {bikeHighlightsData.warranty}
                      </p>
                    </div>
                    <div className="d-flex  align-content-center">
                      <Icon icon="la:shipping-fast" width={23} />
                      <p
                        style={{
                          fontSize: "15px",
                          fontFamily: "Metropolis-medium",
                        }}
                        className="ms-3"
                      >
                        In Stock & Ready to Ship
                      </p>
                    </div>
                    <div className="d-flex  align-content-center">
                      <Icon
                        icon="material-symbols-light:speed-outline"
                        width={23}
                      />
                      <p
                        className="ms-3"
                        style={{
                          fontSize: "15px",
                          fontFamily: "Metropolis-medium",
                        }}
                      >
                        {bikeInfo.name !== "Lil E"
                          ? bikeInfo.variants.length
                            ? `${bikeInfo.variants[variant].variantName} Wheel Size`
                            : bikeHighlightsData.usp
                          : bikeHighlightsData.usp}
                      </p>
                    </div>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex mt-3 pb-1 justify-content-around">
                    <div className=" text-center">
                      {/* <Icon icon="material-symbols-light:arrow-range" /> */}
                      <Icon
                        icon="octicon:arrow-both-16"
                        width={25}
                        style={{ color: "#004dac" }}
                      />
                      <h5
                        className="fw-bold mb-0 mt-1 text-center"
                        style={{
                          fontFamily: "Metropolis-bold",
                          fontSize: "1em",
                        }}
                      >
                        {bikeInfo.variants.length
                          ? bikeInfo.variants[variant].variantName === "5.2AH"
                            ? bikeHighlightsData.bikeSpecs.range
                            : bikeInfo.variants[variant].variantName === "7.8AH"
                            ? "Up to 20"
                            : bikeHighlightsData.bikeSpecs.range
                          : bikeHighlightsData.bikeSpecs.range}
                      </h5>
                      <p className="" style={{ fontSize: "0.8em" }}>
                        KM RANGE
                      </p>
                    </div>
                    <div className=" text-center">
                      {/* <Icon icon="material-symbols-light:arrow-range" /> */}
                      <Icon
                        icon="ic:round-speed"
                        width={25}
                        style={{ color: "#004dac" }}
                      />

                      <h5
                        className="fw-bold mt-1 mb-0 text-center"
                        style={{
                          fontFamily: "Metropolis-bold",
                          fontSize: "1em",
                        }}
                      >
                        {bikeHighlightsData.bikeSpecs.speed}
                      </h5>
                      <p className="" style={{ fontSize: "0.8em" }}>
                        KM/HR SPEED
                      </p>
                    </div>{" "}
                    <div className=" text-center">
                      {/* <Icon icon="material-symbols-light:arrow-range" /> */}
                      <Icon
                        icon="fluent:battery-9-24-regular"
                        width={25}
                        style={{ color: "#004dac" }}
                      />

                      <h5
                        className="fw-bold mt-1 mb-0 text-center"
                        style={{
                          fontFamily: "Metropolis-bold",
                          fontSize: "1em",
                        }}
                      >
                        {bikeInfo.variants.length
                          ? bikeInfo.variants[variant].variantName === "5.2AH"
                            ? bikeHighlightsData.bikeSpecs.battery
                            : bikeInfo.variants[variant].variantName === "7.8AH"
                            ? "7.8"
                            : bikeHighlightsData.bikeSpecs.battery
                          : bikeHighlightsData.bikeSpecs.battery}
                      </h5>
                      <p className="" style={{ fontSize: "0.8em" }}>
                        AH BATTERY
                      </p>
                    </div>{" "}
                  </div>
                  <hr className="mt-2" />
                  {bikeInfo.variants.length ? (
                    <>
                      <div className="mt-3 mb-4">
                        <p style={{ fontFamily: "Metropolis-bold" }}>
                          VARIANTS
                        </p>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "1em" }}
                        >
                          {bikeInfo.variants.map((vari, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setVariant(i);
                                setModel(0);
                                sliderRef.current.swiper.slideTo(1);
                              }}
                              className={`border  d-flex align-items-center p-2 px-3 border-dark w-25 justify-content-center rounded-pill ${
                                variant === i && "active-color"
                              }`}
                            >
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {vari.variantName}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr />
                    </>
                  ) : (
                    ""
                  )}
                  {bikeInfo.name === "T-Rex Pro" ||
                  bikeInfo.name === "Lil E" ? (
                    <div className="mt-3 mb-4">
                      <p style={{ fontFamily: "Metropolis-bold" }}>COLOURS</p>
                      <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                        {bikeInfo.variants[variant].models.map((color, i) => (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModel(i);
                              sliderRef.current.swiper.slideTo(1);
                            }}
                            className={`border  d-flex align-items-center p-2 px-3 border-dark rounded-pill ${
                              model === color.modelIndex && "active-color"
                            }`}
                          >
                            <div
                              className="me-2 rounded-circle"
                              style={{
                                width: "20px",
                                height: "20px",
                                background: `${
                                  bikeInfo.models[color.modelIndex].colorCode
                                }`,
                              }}
                            ></div>
                            <span
                              style={{
                                fontSize: "15px",
                                fontFamily: "Metropolis-bold",
                              }}
                            >
                              {bikeInfo.models[color.modelIndex].colorName}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : bikeInfo.variants.length ? (
                    <div className="mt-3 mb-4">
                      <p style={{ fontFamily: "Metropolis-bold" }}>COLOURS</p>
                      <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                        {bikeInfo.variants[variant].models.map((color, i) => (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModel(i);
                              sliderRef.current.swiper.slideTo(1);
                            }}
                            className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                              model === i && "active-color"
                            }`}
                          >
                            <div
                              className="me-2 rounded-circle"
                              style={{
                                width: "20px",
                                height: "20px",
                                background: `${bikeInfo.models[color].colorCode}`,
                              }}
                            ></div>
                            <span
                              style={{
                                fontSize: "15px",
                                fontFamily: "Metropolis-bold",
                              }}
                            >
                              {bikeInfo.models[color].colorName}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3 mb-4">
                      <p style={{ fontFamily: "Metropolis-bold" }}>COLOURS</p>
                      <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                        {bikeInfo.models.map((color, i) => (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModel(i);
                              sliderRef.current.swiper.slideTo(1);
                            }}
                            className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                              model === i && "active-color"
                            }`}
                          >
                            <div
                              className="me-2 rounded-circle"
                              style={{
                                width: "20px",
                                height: "20px",
                                background: `${color.colorCode}`,
                              }}
                            ></div>
                            <span
                              style={{
                                fontSize: "15px",
                                fontFamily: "Metropolis-bold",
                              }}
                            >
                              {color.colorName}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="mt-4">
                    {bikeInfo.name !== "Neo" && (
                      <Link
                        to={"/find-e-bicycle-store"}
                        className="btn w-100 p-3 text-em rounded-5"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #004DAC",
                          fontFamily: "Metropolis-bold",
                        }}
                      >
                        BUY NOW AT YOUR NEAREST DEALERSHIP
                      </Link>
                    )}
                    <div className="d-flex mt-3 " style={{ gap: "2em" }}>
                      <div className="w-100">
                        <a
                          href={bikeHighlightsData.links.amazon}
                          target="_blank"
                          className="btn d-flex justify-content-center align-items-center w-100 p-3 text-light rounded-5"
                          style={{
                            border: "1px solid #004DAC",
                            fontSize: "13px",
                            gap: "1em",
                            background: "#004dac",
                            fontFamily: "Metropolis-bold",
                          }}
                          rel="noreferrer"
                        >
                          BUY ON
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/bi_amazon.png"
                            className="img-fluid "
                            style={{ width: "15px" }}
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="w-100">
                        <a
                          href={bikeHighlightsData.links.flipkart}
                          target="_blank"
                          className="btn d-flex justify-content-center align-items-center w-100 p-3 text-light rounded-5"
                          style={{
                            border: "1px solid #004DAC",
                            fontSize: "13px",
                            gap: "1em",
                            background: "#004dac",
                            fontFamily: "Metropolis-bold",
                          }}
                          rel="noreferrer"
                        >
                          BUY ON
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/simple-icons_flipkart.png"
                            className="img-fluid "
                            style={{ width: "15px" }}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                </div>
                <div
                  className="background-em d-flex position-sticky align-items-center justify-content-center p-3"
                  style={{ gap: "3em", bottom: "0%", height: "auto" }}
                >
                  <div>
                    {bikeInfo.slashPrice > 0 && (
                      <h4
                        className="text-light text-center mb-0"
                        style={{ fontFamily: "Metropolis-bold" }}
                      >
                        {bikeInfo.name === "T-Rex Pro" ||
                        bikeInfo.name === "Lil E"
                          ? Math.trunc(
                              ((bikeInfo.variants[variant]?.models.find(
                                (m) => m.modelIndex === model
                              )?.slashPrice -
                                bikeInfo.variants[variant]?.models.find(
                                  (m) => m.modelIndex === model
                                )?.price) /
                                bikeInfo.variants[variant]?.models.find(
                                  (m) => m.modelIndex === model
                                )?.slashPrice) *
                                100
                            )
                          : Math.trunc(
                              (((bikeInfo.models[
                                bikeInfo.variants.length
                                  ? bikeInfo.variants[variant].models[model]
                                  : model
                              ].slashPrice
                                ? bikeInfo.models[
                                    bikeInfo.variants.length
                                      ? bikeInfo.variants[variant].models[model]
                                      : model
                                  ].slashPrice
                                : bikeInfo.slashPrice) -
                                (bikeInfo.models[
                                  bikeInfo.variants.length
                                    ? bikeInfo.variants[variant].models[model]
                                    : model
                                ].price
                                  ? bikeInfo.models[
                                      bikeInfo.variants.length
                                        ? bikeInfo.variants[variant].models[
                                            model
                                          ]
                                        : model
                                    ].price
                                  : bikeInfo.accPrice)) /
                                (bikeInfo.models[
                                  bikeInfo.variants.length
                                    ? bikeInfo.variants[variant].models[model]
                                    : model
                                ].slashPrice
                                  ? bikeInfo.models[
                                      bikeInfo.variants.length
                                        ? bikeInfo.variants[variant].models[
                                            model
                                          ]
                                        : model
                                    ].slashPrice
                                  : bikeInfo.slashPrice)) *
                                100
                            )}
                        {"%"} OFF
                      </h4>
                    )}{" "}
                    <div className="bg-light rounded-5  text-center text-em ">
                      <h4 style={{ fontFamily: "Metropolis-bold" }}>SALE</h4>
                    </div>
                    <small
                      style={{
                        fontSize: "0.8em",
                        fontFamily: "Metropolis-medium",
                      }}
                      className=" d-block text-center text-light "
                    >
                      Limited Period Offer
                    </small>
                  </div>

                  <div className="">
                    {bikeInfo.inStock ? (
                      bikeInfo.name === "T-Rex Pro" ||
                      bikeInfo.name === "Lil E" ? (
                        bikeInfo.variants[variant]?.models.find(
                          (m) => m.modelIndex === model
                        )?.inStock ? (
                          <button
                            className="btn btn-light w-100 text-em border-0 rounded-5 pt-3 pb-3 p-3 ps-5 pe-5"
                            style={{
                              padding: "1em 4em",
                              fontFamily: "Metropolis-bold",
                            }}
                            onClick={() =>
                              addCart(bikeInfo._id, model, 1, 1, variant)
                            }
                          >
                            BUY NOW
                          </button>
                        ) : (
                          <a
                            className="btn btn-light w-100 text-em disabled border-0 rounded-5 pt-3 pb-3 p-3 text-decoration-none"
                            style={{
                              fontFamily: "Metropolis-bold",
                            }}
                            href=""
                          >
                            OUT OF STOCK
                          </a>
                        )
                      ) : bikeInfo.models[
                          bikeInfo.variants.length
                            ? bikeInfo.variants[variant].models[model]
                            : model
                        ].inStock ? (
                        <button
                          className="btn btn-light w-100 text-em border-0 rounded-5 pt-3 pb-3 p-3 ps-5 pe-5"
                          style={{
                            padding: "1em 4em",
                            fontFamily: "Metropolis-bold",
                          }}
                          onClick={() =>
                            addCart(bikeInfo._id, model, 1, 1, variant)
                          }
                        >
                          BUY NOW
                        </button>
                      ) : (
                        <a
                          className="btn btn-light w-100 text-em disabled border-0 rounded-5 pt-3 pb-3 p-3 text-decoration-none"
                          style={{
                            fontFamily: "Metropolis-bold",
                          }}
                          href=""
                        >
                          OUT OF STOCK
                        </a>
                      )
                    ) : (
                      <a
                        className="btn btn-light w-100 text-em disabled border-0 rounded-5 pt-3 pb-3 p-3 text-decoration-none"
                        style={{
                          fontFamily: "Metropolis-bold",
                        }}
                        href=""
                      >
                        OUT OF STOCK
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default ProductScreen;
